import { TagAPI } from "../api";
import i18n from "../i18n";

export const getTags = async (force?: boolean) => {
  try {
    const items = await TagAPI.getTags();
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getTagByIndex = async (index?: string) => {
  try {
    const items = await getTags();
    const item = items?.find((item) => item.tagslug === index);

    if (item) {
      return item;
    } else {
      throw new Error(`${index} ${i18n.t("NotFound")}`);
    }
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const editTag = async (data: TagResponse) => {
  try {
    const response = await TagAPI.editTag(data);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const addTag = async (data: TagResponse) => {
  try {
    const response = await TagAPI.addTag(data);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const deleteTag = async (id: TagResponse["tag_id"]) => {
  try {
    const response = await TagAPI.deleteTag(id);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};
