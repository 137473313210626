import { sendRequest } from "../shared/lib/utils/sendRequest";
import { ENDPOINTS } from "./endpoints";

const baseUrl = ENDPOINTS.API_ROOT;

export const getAuthors = async (): Promise<Nullable<AuthorResponse[]>> => {
  return sendRequest(baseUrl + ENDPOINTS.AUTHORS.PATH);
};

export const getAuthorStatistics = async (
  id: AuthorResponse["author_id"]
): Promise<Nullable<AuthorStatisticsResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.AUTHORS.STAT + id);
};

export const deleteAuthor = async (id: number) => {
  return sendRequest(baseUrl + ENDPOINTS.AUTHORS.DELETE, {
    body: { author_id: id },
  });
};

export const editAuthor = async (
  data: AuthorResponse
): Promise<Nullable<AuthorResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.AUTHORS.EDIT, { body: data });
};

export const addAuthor = async (
  data: AuthorResponse
): Promise<Nullable<AuthorResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.AUTHORS.ADD, { body: data });
};
