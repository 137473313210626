type TGroup = {
  question: string;
  answer: string;
};
type dataType = {
  title: string;
  list: TGroup[];
};
export default class Faq {
  api: API;
  readOnly?: boolean;
  data: dataType;

  titlePlaceholder: string;
  questionPlaceholder: string;
  answerPlaceholder: string;

  static DEFAULT_TITLE_PLACEHOLDER: string;
  static DEFAULT_QUESTION_PLACEHOLDER: string;
  static DEFAULT_ANSWER_PLACEHOLDER: string;

  constructor({ data, config, api }: IEditor<dataType>) {
    this.api = api;

    this.titlePlaceholder =
      config.titlePlaceholder || Faq.DEFAULT_TITLE_PLACEHOLDER;
    this.questionPlaceholder =
      config.questionPlaceholder || Faq.DEFAULT_QUESTION_PLACEHOLDER;
    this.answerPlaceholder =
      config.answerPlaceholder || Faq.DEFAULT_ANSWER_PLACEHOLDER;

    this.data = {
      title: data?.title || "Часто задаваемые вопросы",
      list: data?.list || [],
    };
  }
  static get toolbox() {
    return {
      icon: '<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.8 0.333252C0.358172 0.333252 0 0.691424 0 1.13325C0 1.57508 0.358172 1.93325 0.8 1.93325C1.24183 1.93325 1.6 1.57508 1.6 1.13325C1.6 0.691424 1.24183 0.333252 0.8 0.333252ZM4.8 0.333252C4.35817 0.333252 4 0.691424 4 1.13325C4 1.57508 4.35817 1.93325 4.8 1.93325H15.2C15.6418 1.93325 16 1.57508 16 1.13325C16 0.691424 15.6418 0.333252 15.2 0.333252H4.8ZM0.8 3.53325C0.358172 3.53325 0 3.89142 0 4.33325C0 4.77508 0.358172 5.13325 0.8 5.13325C1.24183 5.13325 1.6 4.77508 1.6 4.33325C1.6 3.89142 1.24183 3.53325 0.8 3.53325ZM4.8 3.53325C4.35817 3.53325 4 3.89142 4 4.33325C4 4.77508 4.35817 5.13325 4.8 5.13325H15.2C15.6418 5.13325 16 4.77508 16 4.33325C16 3.89142 15.6418 3.53325 15.2 3.53325H4.8ZM0.8 8.33325C0.358172 8.33325 0 8.69142 0 9.13325C0 9.57508 0.358172 9.93325 0.8 9.93325C1.24183 9.93325 1.6 9.57508 1.6 9.13325C1.6 8.69142 1.24183 8.33325 0.8 8.33325ZM4.8 8.33325C4.35817 8.33325 4 8.69142 4 9.13325C4 9.57508 4.35817 9.93325 4.8 9.93325H15.2C15.6418 9.93325 16 9.57508 16 9.13325C16 8.69142 15.6418 8.33325 15.2 8.33325H4.8ZM0.8 11.5333C0.358172 11.5333 0 11.8914 0 12.3333C0 12.7751 0.358172 13.1333 0.8 13.1333C1.24183 13.1333 1.6 12.7751 1.6 12.3333C1.6 11.8914 1.24183 11.5333 0.8 11.5333ZM4.8 11.5333C4.35817 11.5333 4 11.8914 4 12.3333C4 12.7751 4.35817 13.1333 4.8 13.1333H15.2C15.6418 13.1333 16 12.7751 16 12.3333C16 11.8914 15.6418 11.5333 15.2 11.5333H4.8Z" fill="black"/></svg>',
      title: "Faq",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  get CSS(): Record<string, string> {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-faq",
      title: "cdx-faq__title",
      input: this.api.styles.input,
      question: "cdx-faq__question",
      answer: "cdx-faq__answer",
      group: "cdx-faq__group",
      groupClose: "cdx-faq__group-close",
      groupIterator: "cdx-faq__group-iterator",
      addButton: "cdx-faq__add-button",
      settingsWrapper: "cdx-faq-settings",
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);

    const title = this._make("div", [this.CSS.input, this.CSS.title], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.title,
    });

    title.dataset.placeholder = this.titlePlaceholder;

    const addButton = this._make("button", [this.CSS.addButton], {
      innerHTML: "Добавить новый пункт",
      className:
        "cdx-input cdx-faq__add-button btn btn-fond black scale-up lighten-up",
    });
    addButton.addEventListener("click", () => {
      addGroup(
        {
          question: "",
          answer: "",
        },
        container.getElementsByClassName(this.CSS.group).length
      );
    });

    const addGroup = (item: TGroup, i: number) => {
      const group = this._make("div", [this.CSS.group]);

      const question = this._make("div", [this.CSS.input, this.CSS.question], {
        contentEditable: !this.readOnly,
        innerHTML: item.question,
      });

      const answer = this._make("div", [this.CSS.input, this.CSS.answer], {
        contentEditable: !this.readOnly,
        innerHTML: item.answer,
      });

      const close = this._make("button", [this.CSS.groupClose], {
        innerHTML: '<em class="cdx-faq__group-close-icon" />',
      });

      const iterator = this._make("span", [this.CSS.groupIterator], {
        innerHTML: i + 1,
      });

      question.dataset.placeholder = this.questionPlaceholder;
      answer.dataset.placeholder = this.answerPlaceholder;

      const closeListener = () => {
        close.removeEventListener("click", closeListener);
        container.removeChild(group);
      };

      close.addEventListener("click", closeListener);

      group.appendChild(iterator);
      group.appendChild(question);
      group.appendChild(answer);
      group.appendChild(close);
      container.insertBefore(group, addButton);
    };

    container.appendChild(title);
    container.appendChild(addButton);
    this.data.list.forEach(addGroup);

    return container;
  }

  save(faqElement: HTMLDivElement) {
    const title = faqElement.querySelector(`.${this.CSS.title}`);
    const groups = faqElement.querySelectorAll(`.${this.CSS.group}`);

    return Object.assign(this.data, {
      title: title?.innerHTML,
      list: Array.from(groups)
        .map((group) => {
          const question = group.querySelector(`.${this.CSS.question}`);
          const answer = group.querySelector(`.${this.CSS.answer}`);
          return {
            question: question?.innerHTML,
            answer: answer?.innerHTML,
          };
        })
        .filter((group) => group.question !== "" && group.answer !== ""),
    });
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
      alignment: {},
    };
  }

  _make(
    tagName: string,
    classNames: string[] = [],
    attributes: Record<string, any> = {}
  ) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      (el as any)[attrName] = attributes[attrName];
    }

    return el;
  }
}
