export enum EFilterTabs {
  all,
  draft,
}

export enum EDateOptions {
  all,
  month,
  week,
}

export const DATE_OPTIONS = [
  { value: EDateOptions.week, label: "DateFilterWeek" },
  { value: EDateOptions.month, label: "DateFilterMonth" },
  { value: EDateOptions.all, label: "DateFilterAll" },
];

export const PAGINATION_SIZE = 18;

export const FILTER_TABS = [
  { value: EFilterTabs.all, label: "All" },
  { value: EFilterTabs.draft, label: "Draft" },
];
