import { AuthorAPI } from "../api";

export const getAuthors = async (force?: boolean) => {
  try {
    const items = await AuthorAPI.getAuthors();
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getAuthorById = async (
  id?: number
): Promise<Nullable<AuthorResponse>> => {
  try {
    const items = await getAuthors();
    const item = items?.find((item) => item.author_id === id);

    if (item) {
      return item;
    } else {
      throw new Error(`${id} not found`);
    }
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const deleteAuthor = async (id: number) => {
  try {
    return await AuthorAPI.deleteAuthor(id);
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const editTag = async (data: AuthorResponse) => {
  try {
    const response = await AuthorAPI.editAuthor(data);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const addTag = async (data: AuthorResponse) => {
  try {
    const response = await AuthorAPI.addAuthor(data);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};
