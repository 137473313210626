import { AuthorStore } from "@/app/providers/MobxStore";
import Author from "@/components/Authors/Author";
import { Select } from "@/widgets/Form";
import { IFaqTag } from "@op/entities";
import classNames from "classnames";
import { t } from "i18next";
import { Control, Controller, FieldErrors } from "react-hook-form";

interface IProps {
  name: string;
  items?: IFaqTag[];
  control: Control<any>;
  errors: FieldErrors;
}

export const SelectAuthorController = ({
  name,
  items,
  errors,
  control,
}: IProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: "Выберите автора" }}
      render={({ field: { value, onChange } }) => (
        <Select
          className="select-check editor-header__rubric"
          value={value}
          setValue={onChange}
          isInvalid={!!errors.author_id}
          items={AuthorStore.data?.map(
            ({ author_id, name, initials, avatar }) => ({
              value: author_id,
              label: { name, initials, avatar },
            })
          )}
          searchFunction={({ label, search }) =>
            label.name.toLowerCase().includes(search)
          }
          searchPlaceholder="Искать автора"
          button={(selectedItem) => (
            <button
              className={classNames([
                "select__btn",
                selectedItem ? "" : "btn btn-ghost ghost size-s",
                !!errors.author_id && "red",
              ])}
            >
              {selectedItem ? (
                <>
                  <Author
                    className="editor-header__select-author"
                    initials={selectedItem.initials}
                    avatar={selectedItem.avatar}
                    name={selectedItem.name}
                  />
                  <span className="icon icon-cursor-down select__btn-icon" />
                </>
              ) : (
                <>
                  <i
                    className={
                      selectedItem ? "btn__text select__btn-text" : "btn__text"
                    }
                  >
                    {t("ChooseAuthor")}
                  </i>
                  <span className="icon icon-cursor-down select__btn-icon" />
                </>
              )}
            </button>
          )}
          option={(author) => {
            return (
              <Author
                className="editor-header__select-author dropdown__text"
                initials={author.initials}
                avatar={author.avatar}
                name={author.name}
              />
            );
          }}
        />
      )}
    />
  );
};
