import { OpTheme } from '../providers';
import { transformPxToVwInStyleObject } from './transformPxToVwInStyleObject';

export const pxToVwWithBreakpoints = (styles: { [key: string]: string | number }) => {
  const stylesSmall = transformPxToVwInStyleObject(styles, OpTheme.breakpoints.values.desktopSmall);
  const stylesStatic = styles;
  const stylesLarge = transformPxToVwInStyleObject(styles, OpTheme.breakpoints.values.desktopLarge);
  return {
    ...stylesStatic,
    [OpTheme.breakpoints.between('tabletStart', 'desktopSmall')]: stylesSmall,
    [OpTheme.breakpoints.up('desktopLarge')]: stylesLarge,
  };
};
