import { sendRequest } from "../shared/lib/utils/sendRequest";
import { ENDPOINTS } from "./endpoints";

const baseUrl = ENDPOINTS.API_ROOT;

export const getTags = (): Promise<Nullable<TagResponse[]>> => {
  return sendRequest(baseUrl + ENDPOINTS.TAGS.PATH);
};

export const addTag = (
  data: TagResponse
): Promise<Nullable<{ id: number }>> => {
  return sendRequest(baseUrl + ENDPOINTS.TAGS.ADD, { body: data });
};

export const editTag = (data: TagResponse): Promise<Nullable<TagResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.TAGS.EDIT, { body: data });
};

export const deleteTag = (id: number): Promise<Nullable<TagResponse[]>> => {
  return sendRequest(baseUrl + ENDPOINTS.TAGS.DELETE, { body: { tag_id: id } });
};
