import { EVoteStatus } from "@/components/Editorjs/tools/Vote/editor";
import * as ArticleController from "@/controllers/article-controller";
import { OutputData } from "@editorjs/editorjs";

export const prepareBlocksData = async (savedContent: OutputData) => {
  return Promise.all(
    savedContent.blocks.map(async (block, key) => {
      if (block.type === "vote" && typeof block.data?.status !== "undefined") {
        const status = block.data?.status;
        delete block.data?.status;

        const request =
          status === EVoteStatus.isNew
            ? ArticleController.addVoteBlock
            : ArticleController.editVoteBlock;
        const response = await request(block.data);
        if (response) {
          block.data = { ...block.data, vote_id: response.vote_id };
        } else {
          throw new Error();
        }
      }
      if (block.type === "linkTool" && block.data) {
        // remove link block if data is empty\wrong
        if (Object.keys(block.data.meta).length === 0) {
          savedContent.blocks.splice(key, 1);
        }
      }
    })
  );
};
