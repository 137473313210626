import { Error404 } from "@/pages/Error";
import { FaqArticles } from "@/pages/FaqArticles";
import { FaqTags } from "@/pages/FaqTags";
import { Login } from "@/pages/Login";
import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";

import Authors from "../../../../components/Authors/Authors";
import JournalArticles from "../../../../components/JournalArticles/JournalArticles";
import JournalTags from "../../../../components/JournalTags/JournalTags";
import MainContainer from "../../../../components/MainContainer";
import ProfileContainer from "../../../../components/ProfileContainer";
import UserStore from "../../MobxStore/model/UserStore";
import { ROUTES } from "../model/consts/routerConsts";
import { Private } from "./PrivateRoute";

function Router(): ReactElement {
  const { loading, isAuth } = UserStore;

  if (loading) return <></>;

  return (
    <Routes>
      <Route element={<MainContainer />}>
        {!isAuth ? (
          <Route path={ROUTES.HOME.path} element={<Login />} />
        ) : (
          <Route
            element={
              <Private Component={ProfileContainer} allowed={!!isAuth} />
            }
          >
            <Route path={ROUTES.HOME.path} element={<JournalArticles />} />
            <Route path={ROUTES.GENERAL.AUTHORS.path} element={<Authors />} />
            <Route path={ROUTES.JOURNAL.TAGS.path} element={<JournalTags />} />
            <Route path={ROUTES.FAQ.TAGS.path} element={<FaqTags />} />
            <Route path={ROUTES.FAQ.ARTICLES.path} element={<FaqArticles />} />
          </Route>
        )}
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
}

export default observer(Router);
