import React from "react";

interface ICardArticleThumbnailProps {
  thumbailUrl: string;
}

const CardArticleThumbnail = ({ thumbailUrl }: ICardArticleThumbnailProps) => {
  return (
    <div
      className="profile-article__thumbnail"
      style={{ backgroundImage: `url(${thumbailUrl})` }}
    />
  );
};

export default CardArticleThumbnail;
