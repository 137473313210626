import i18n from "../i18n";
import { sendRequest } from "../shared/lib/utils/sendRequest";
import { ENDPOINTS } from "./endpoints";

const baseUrl = ENDPOINTS.API_ROOT;

export const getToken = async (
  data: LoginRequest
): Promise<Nullable<{ access_token: string }>> => {
  return sendRequest(baseUrl + ENDPOINTS.AUTH.GET_TOKEN, {
    body: data,
    errorMessage: i18n.t("WrongPassword"),
  });
};

export const getUser = async (
  token: string
): Promise<Nullable<UserResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.AUTH.GET_USER, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    errorMessage: i18n.t("AccessTokenHasExpired"),
  });
};
