'use client';

import buttonsScss from './buttons.module.scss';

export const buttons = {
  scaleUp: {
    hover: buttonsScss.scaleUpHover,
    active: buttonsScss.scaleUpActive,
  },
  scaleDown: {
    hover: buttonsScss.scaleDownHover,
    active: buttonsScss.scaleDownActive,
  },
  opacityDown: {
    hover: {
      opacity: 0.8,
    },
    active: {
      scale: 0.9,
    },
  },
  buttonDisplay: buttonsScss.defaultDisplay,
  defaultButtonColor: buttonsScss.defaultColor,
  shadow: buttonsScss.shadow,
  transition: buttonsScss.baseTransition,
};
