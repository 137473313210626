'use client';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { OpTheme } from '../../providers';
import { pxToVwWithBreakpoints } from '../../utils';

export const GridTheme = extendTheme({
  breakpoints: {...OpTheme.breakpoints},
  spacing: OpTheme.spacing,
  components:  {
    MuiGrid2: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...pxToVwWithBreakpoints({
            '--Grid-columnSpacing': '40px'
          }),
          [OpTheme.breakpoints.up('tabletStart')]: {
            '--Grid-columns': 12,
            '--Grid-rowSpacing': '0px',
          },
          [OpTheme.breakpoints.down('mobile')]: {
            '--Grid-columnSpacing': '20px',
            '--Grid-columns': 4,
            '--Grid-rowSpacing': '0px',
          }
        })
      }
    }
  }
})