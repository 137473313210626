import { makeAutoObservable } from "mobx";

import * as TagController from "../../../../controllers/tag-controller";

class TagStore {
  data: Record<string, TagResponse> = {};
  loading: boolean = false;
  error: any;

  constructor() {
    makeAutoObservable(this);
  }

  fetchTags = async () => {
    this.data = {};
    this.loading = true;
    this.error = "";

    try {
      const tagsResponse = await TagController.getTags();
      tagsResponse?.forEach((tag) => (this.data[tag.tagslug] = tag));
    } catch (error) {
      this.error = error;
    }
    this.loading = false;
  };

  deleteTag = async (tag_id: TagResponse["tag_id"]) => {
    try {
      const response = await TagController.deleteTag(tag_id);
      if (response) {
        Object.keys(this.data).forEach((key) => {
          if (this.data[key].tag_id === tag_id) {
            delete this.data[key];
          }
        });
      }
    } catch (error) {
      this.error = error;
    }
    this.loading = false;
  };
}

const tagStore = new TagStore();
export default tagStore;
