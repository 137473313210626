import { makeAutoObservable } from "mobx";

import { AuthorAPI } from "../../../../api";

class AuthorStore {
  data: AuthorResponse[] = [];
  loading: boolean = false;
  error: any;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAuthors = async () => {
    this.data = [];
    this.loading = true;
    this.error = "";

    try {
      const authorResponse = await AuthorAPI.getAuthors();
      this.data = authorResponse || [];
    } catch (error) {
      this.error = error;
    }
    this.loading = false;
  };
  deleteAuthor = async (id: AuthorResponse["author_id"]) => {
    this.data = this.data.filter((author) => author.author_id !== id);
  };
}

const authorStore = new AuthorStore();
export default authorStore;
