import classNames from "classnames";
import React, { ReactElement, useState } from "react";

interface IProfileCard {
  cells: ReactElement[];
  className: string;
  cardId: number;
  cardLink: string | number;
  onDelete: (id: number) => void;
  onEdit: (index: string | number) => void;
  onClick?: (index: string | number) => void;
}

const ProfileCard = ({
  cells,
  className,
  cardId,
  cardLink,
  onDelete,
  onClick,
  onEdit,
}: IProfileCard) => {
  const [loading] = useState(false);

  const handleEdit = () => {
    onEdit(cardLink);
  };

  const handleClick = () => {
    onClick?.(cardLink) || onEdit(cardLink);
  };

  return (
    <article
      className={classNames([
        "profile-card",
        className,
        loading && "is-loading",
      ])}
    >
      <button className="profile-card__base-btn" onClick={handleClick} />
      {cells.map((cell, index) => (
        <div
          key={index}
          className={classNames(["profile-card__cell", `cell-${index + 1}`])}
        >
          {cell}
        </div>
      ))}
      <div className="profile-card__cell">
        <div className="profile-card__btns">
          <button
            className="btn-icon profile-card__btn"
            onClick={() => onDelete(cardId)}
          >
            <span className="icon icon-close" />
          </button>
          <button className="btn-icon profile-card__btn" onClick={handleEdit}>
            <span className="icon icon-edit" />
          </button>
        </div>
      </div>
    </article>
  );
};

export default ProfileCard;
