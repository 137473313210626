type dataType = {
  numeral: string;
  sign: string;
};
export default class Numeric {
  api: API;
  readOnly: boolean = false;
  data: dataType;

  numeralPlaceholder: string;
  signPlaceholder: string;

  static DEFAULT_NUMERAL_PLACEHOLDER: string;
  static DEFAULT_SIGN_PLACEHOLDER: string;

  constructor({ data, config, api }: IEditor<dataType>) {
    this.api = api;

    this.numeralPlaceholder =
      config.numeralPlaceholder || Numeric.DEFAULT_NUMERAL_PLACEHOLDER;
    this.signPlaceholder =
      config.signPlaceholder || Numeric.DEFAULT_SIGN_PLACEHOLDER;

    this.data = {
      numeral: data?.numeral || "",
      sign: data?.sign || "",
    };
  }
  static get toolbox() {
    return {
      icon: '<svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.333252 15C0.333252 15.5523 0.780967 16 1.33325 16H9.27676C9.82904 16 10.2768 15.5523 10.2768 15V14.5819C10.2768 14.0296 9.82904 13.5819 9.27676 13.5819H6.50189C5.56211 13.5819 5.1117 12.3687 5.76878 11.6968C7.54285 9.8828 9.66659 7.10573 9.66659 4.13559C9.66659 2.0339 8.19766 0 5.19201 0C2.97209 0 1.30083 1.13272 0.648113 2.99093C0.495048 3.42669 0.74321 3.88443 1.16472 4.07323L1.53073 4.23717C2.09727 4.49093 2.73696 4.1531 3.07315 3.63123C3.49938 2.96958 4.12466 2.59887 4.89822 2.59887C6.20896 2.59887 6.90952 3.38983 6.90952 4.65537C6.90952 7.6122 3.2073 11.1129 0.632354 13.628C0.440433 13.8155 0.333252 14.072 0.333252 14.3403V15Z" fill="black"/></svg>',
      title: "Numeric",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  get CSS(): Record<string, string> {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-numeric",
      numeral: "cdx-numeric__numeral",
      input: this.api.styles.input,
      sign: "cdx-numeric__sign",
      settingsWrapper: "cdx-numeric-settings",
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);

    const numeral = this._make("div", [this.CSS.input, this.CSS.numeral], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.numeral,
    });

    const sign = this._make("div", [this.CSS.input, this.CSS.sign], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.sign,
    });

    numeral.dataset.placeholder = this.numeralPlaceholder;
    sign.dataset.placeholder = this.signPlaceholder;

    container.appendChild(numeral);
    container.appendChild(sign);

    return container;
  }

  save(numericElement: HTMLDivElement) {
    const numeral = numericElement.querySelector(`.${this.CSS.numeral}`);
    const sign = numericElement.querySelector(`.${this.CSS.sign}`);

    return Object.assign(this.data, {
      numeral: numeral?.innerHTML,
      sign: sign?.innerHTML,
    });
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
      alignment: {},
    };
  }

  _make(
    tagName: string,
    classNames: string[] = [],
    attributes: Record<string, any> = {}
  ) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      (el as any)[attrName] = attributes[attrName];
    }

    return el;
  }
}
