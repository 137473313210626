'use client';

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import mediaQuery from 'css-mediaquery';

import {
  LOCALSTORAGE_DEVICE_WIDTH_KEY,
  breakpoints,
  colors,
  fontFamilies,
  fontWeightBase,
  scopedCssBaselineRoot,
  shadowsArray,
} from '../../consts';
import { fontMedium, fontRegular, fontSemiBold } from '../../consts/theme/fonts';
import spacing from '../../consts/theme/spacing.module.scss';
import { getMobileDetect } from '../../utils/getMobile';
import './themeTypes';

const ssrMatchMedia = (query: string) => {
  const { isMobile } = getMobileDetect();
  const defaultWidth = isMobile() ? breakpoints.mobile : breakpoints.desktopSmall;

  return {
    matches: mediaQuery.match(query, {
      width: typeof window === 'undefined' ? defaultWidth : localStorage?.getItem(LOCALSTORAGE_DEVICE_WIDTH_KEY),
    }),
  };
};

export const ThemeSchema = {
  colorSchemes: {
    dark: {
      palette: {
        color: {
          default: colors.white.t_100,
        },
        background: {
          default: colors.black.t_100,
          paper: 'none',
        },
        backgrounds: {
          default: colors.black.t_100,
          level0: colors.gray.c_1000,
        },
        opColors: {
          gray1: colors.base.gray1,
          components: {
            chips: {
              default: colors.gray_steel.c_800,
              lightenUp: colors.gray_steel.c_600,
              lightenDown: colors.gray_steel.c_800,
            },
          },
          buttonColors: {
            blue: {
              default: colors.base.blue,
              lightenUp: '#3d73ff',
              lightenDown: '#003edc',
            },
            white: {
              default: colors.white.t_100,
              lightenUp: colors.white.t_100,
              lightenDown: colors.white.t_80,
            },
            black: {
              default: colors.white.t_100,
              lightenUp: colors.white.t_100,
              lightenDown: colors.white.t_80,
            },
            red: {
              default: colors.base.red,
              lightenUp: '#ff563d',
              lightenDown: '#d91c00',
            },
            green: {
              default: colors.toxic.c_0,
              lightenUp: '#dcff5e',
              lightenDown: '#9feb00',
            },
          },
        },
      },
    },
    light: {
      palette: {
        color: {
          default: colors.black.t_100,
        },
        background: {
          default: colors.white.t_100,
          paper: 'none',
        },
        backgrounds: {
          default: colors.gray.c_50,
          level0: colors.gray.c_100,
        },
        opColors: {
          gray1: colors.base.gray1LightTheme,
          components: {
            chips: {
              default: colors.gray_steel.c_0,
              lightenUp: colors.gray_steel.c_300,
              lightenDown: colors.black.t_100,
            },
          },
          buttonColors: {
            blue: {
              default: colors.base.blue,
              lightenUp: '#3d73ff',
              lightenDown: '#003edc',
            },
            white: {
              default: colors.black.t_100,
              lightenUp: colors.black.t_80,
              lightenDown: colors.black.t_100,
            },
            black: {
              default: colors.black.t_100,
              lightenUp: colors.black.t_80,
              lightenDown: colors.black.t_100,
            },
            red: {
              default: colors.base.red,
              lightenUp: '#ff563d',
              lightenDown: '#d91c00',
            },
            green: {
              default: colors.toxic.c_0,
              lightenUp: '#dcff5e',
              lightenDown: '#9feb00',
            },
            dark: {
              default: colors.base.darkGray,
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: fontFamilies.base,
    fontSize: 18,
    fontWeight: fontWeightBase,
    lineHeight: 1.3,
  },
  breakpoints: {
    values: {
      desktopLarge: breakpoints.desktopLarge, // up
      desktopMiddle: breakpoints.desktopMiddle, // up
      desktopSmall: breakpoints.desktopSmall, // up
      desktopStart: breakpoints.desktopStart, // up
      tablet: breakpoints.tablet, // down
      tabletStart: breakpoints.tabletStart, // for system mobile-first
      mobile: breakpoints.mobile, // down
      mobileStart: breakpoints.mobileStart, // for system mobile-first,
      md: breakpoints.tablet,
    },
  },
  spacing: Number(spacing.spacing),
  shadows: shadowsArray,
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia,
        noSsr: false,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...scopedCssBaselineRoot,
        html: [
          { '@font-face': fontRegular },
          { '@font-face': fontMedium },
          { '@font-face': fontSemiBold },
          { height: '100%' },
          { WebkitTextSizeAdjust: 'none' },
        ],
        ':root': {
          '--Grid-rowSpacing': '0px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black.t_100,
        },
      },
    },
  },
};

export const OpTheme = extendTheme(ThemeSchema);
