import { getEnv } from '../lib/getEnv';

export const DOMAIN = getEnv('DOMAIN');

export const PROMO_URL = getEnv('PROMO_URL');
export const ADMIN_URL = getEnv('ADMIN_URL');
export const ASSETS_URL = getEnv('ASSETS_URL');
export const JOURNAL_URL = getEnv('JOURNAL_URL');
export const CLIENT_PORTAL_URL = getEnv('CLIENT_PORTAL_URL');
export const SENTRY_URL = getEnv('SENTRY_URL');

export const YA_METRICA_ID = getEnv('YA_METRICA_ID');
export const YA_MAP_TOKEN = getEnv('YA_MAP_TOKEN');
export const GTM_ID = getEnv('GTM_ID');
export const JIVO_ID = getEnv('JIVO_ID');
export const SENTRY_ID = getEnv('SENTRY_ID');

export const API_PROMO_URL = getEnv('API_PROMO_URL');
export const API_PROMO_LEGACY_URL = getEnv('API_PROMO_LEGACY_URL');
export const API_CLAIMS_URL = getEnv('API_CLAIMS_URL');

export const IS_PRODUCTION = getEnv('IS_PRODUCTION') === 'true';

export const S3_URL = getEnv('S3_URL');
export const PROMO_STATIC_URL = getEnv('PROMO_STATIC_URL');
export const JOURNAL_STATIC_URL = getEnv('JOURNAL_STATIC_URL');
