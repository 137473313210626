import { AuthorStore, MarkupStore, UserStore } from "@/app/providers/MobxStore";
import { ROUTES } from "@/app/providers/Router/model/consts/routerConsts";
import CardArticleInfo from "@/components/CardArticleInfo";
import CardArticleThumbnail from "@/components/CardArticleThumbnail";
import PaginationArrows from "@/components/PaginationArrows";
import ProfileCard from "@/components/ProfileCard";
import { ConfirmDeleteModal } from "@/features/Modal";
import { useDidUpdateEffect } from "@/shared/lib/hooks/useDidUpdateEffect";
import useModalConfirm from "@/shared/lib/hooks/useModalComfirm";
import useModalOpen from "@/shared/lib/hooks/useModalOpen";
import { isFutureDate } from "@/shared/lib/utils/isFutureDate";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import { FaqArticlesModalEditor as ModalEditor } from "@/widgets/FaqArticlesModalEditor";
import { Input, Select } from "@/widgets/Form";
import {
  IFaqArticle,
  IFaqArticleDetail,
  PROMO_URL,
  deleteFaqArticle,
  getFaqArticleDetail,
  getFaqArticleList,
} from "@op/entities";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  DATE_OPTIONS,
  EDateOptions,
  EFilterTabs,
  FILTER_TABS,
  PAGINATION_SIZE,
} from "../model/consts/faqArticlesConsts";

function FaqArticles() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();

  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [modalEditorData, setModalEditorData] = useState<IFaqArticleDetail>();
  const [searchString, setSearchString] = useState("");
  const [articles, setArticles] = useState<IFaqArticle[]>([]);
  const [currentFilterTab, setCurrentFilterTab] = useState(EFilterTabs.all);
  const [currentDateFilter, setCurrentDateFilter] = useState(EDateOptions.all);
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);
  const { t } = useTranslation();

  const getArticles = async () => {
    try {
      setLoading(true);
      const [tagListDraftResponse, tagListResponse] = await Promise.all([
        getFaqArticleList({
          token: UserStore.token ?? undefined,
          filterIsDraft: true,
        }),
        getFaqArticleList({}),
      ]);

      setArticles([
        ...tagListDraftResponse.data.items,
        ...tagListResponse.data.items,
      ]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        articles.length,
        !!next
      )
    );
  };

  const handleFilterSelect = (value: number) => {
    setCurrentFilterTab(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handleDateFilterChange = (date?: EDateOptions) => {
    if (date != null) {
      setCurrentDateFilter(date);
      setCurrentPaginationPosition(0);
    }
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      try {
        const response = await deleteFaqArticle({
          id: modalConfirm.id,
          token: UserStore.token!,
        });
        if (response) {
          setModalConfirm({ isOpen: false, id: null });
          await getArticles();
        } else {
          throw new Error();
        }
      } catch (error) {
        notify(t("AnErrorOcurred"));
      }
    }
  };

  const handleEdit = async (data: IFaqArticle) => {
    try {
      const response = await getFaqArticleDetail({ index: data.index });
      if (response && response.data) {
        setModalEditorData(response.data);
        setModalEditorOpened(true);
      }
    } catch (error) {}
  };

  const handleClick = async ({ index, tag_index }: IFaqArticle) => {
    window.open(`${PROMO_URL}/faq/${tag_index}/${index}`, "_blank");
  };

  const handleAdd = async () => {
    setModalEditorData(undefined);
    setModalEditorOpened(true);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await getArticles();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  const filteredList = useMemo(() => {
    return articles
      .filter((article) => (currentFilterTab ? article.is_draft : true))
      .filter((article) => article.title.toLowerCase().includes(searchString))
      .filter((article) => {
        const articleAge = moment().diff(
          moment(article.publication_date_time_utc),
          "days"
        );
        switch (currentDateFilter) {
          case EDateOptions.week:
            return articleAge <= 7;
          case EDateOptions.month:
            return articleAge <= 30;
          default:
            return true;
        }
      });
  }, [articles, currentDateFilter, currentFilterTab, searchString]);

  const paginatedList = useMemo(
    () =>
      filteredList.slice(
        currentPaginationPosition,
        currentPaginationPosition + PAGINATION_SIZE
      ),
    [currentPaginationPosition, filteredList]
  );

  useEffect(() => {
    if (AuthorStore.data && !MarkupStore.creatorModalOpen) {
      getArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthorStore.data, MarkupStore.creatorModalOpen]);

  useDidUpdateEffect(() => {
    !modalEditorOpened && navigate(ROUTES.FAQ.ARTICLES.path);
  }, [modalEditorOpened]);

  if (loading) {
    return (
      <>
        <div className="profile__title-block">
          <strong className="profile__title">{t("TagsList")}</strong>
        </div>
        <div className="profile__body profile-rubrics">
          <div>{t("Loading")}...</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("ArticlesList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("AddArticle2")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <ul className="profile__tabs">
            {FILTER_TABS.map((tab) => (
              <li
                className={classNames(
                  "profile__tab",
                  currentFilterTab === tab.value && "is-active"
                )}
                key={tab.label}
                onClick={() => handleFilterSelect(tab.value)}
              >
                <div className="profile__tab-holder">
                  <strong className="profile__tab-name">{t(tab.label)}</strong>
                  <span className="profile__tab-number">
                    {tab.value === EFilterTabs.draft
                      ? articles.filter((article) => article.is_draft).length
                      : articles.length}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <div className="search-input profile__search">
              <Input
                placeholder={t("SearchByArticles")}
                className="input_stroke profile__search-input"
                value={searchString}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="profile__control-block">
            <Select
              className="profile__filter"
              dropDownClassName="bottom bottom-end"
              value={currentDateFilter}
              setValue={handleDateFilterChange}
              items={DATE_OPTIONS}
              button={(label) => (
                <button className="btn btn-outline lighten-up scale-up black">
                  <i className="btn__text select__btn-text">{t(label!)}</i>
                  <span className="icon icon-cursor-down select__btn-icon"></span>
                </button>
              )}
              option={(item) => (
                <em className="dropdown__text select__text">{t(item)}</em>
              )}
            />
          </div>
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={filteredList.length}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-articles">
        {paginatedList.map((article) => (
          <ProfileCard
            key={article.id}
            className={classNames(
              "card-article",
              article.is_draft && "is-unpublished"
            )}
            cardId={article.id}
            cardLink={article.index}
            onDelete={handleConfirm}
            onEdit={() => handleEdit(article)}
            onClick={() => handleClick(article)}
            cells={[
              <CardArticleThumbnail key={0} thumbailUrl={article.image} />,
              <CardArticleInfo
                key={1}
                title={article.title}
                authorId={article.author_id}
                articleIndex={article.index}
              />,
              <React.Fragment key={2}>
                <p>
                  {moment(article.publication_date_time_utc).format(
                    "DD MMMM YYYY"
                  )}
                </p>
                {isFutureDate(article.publication_date_time_utc) && (
                  <p className="profile-articles__status red">
                    {t("Deffered")}
                  </p>
                )}
              </React.Fragment>,
            ]}
          />
        ))}
        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList.length}
          />
        </div>
      </div>
      <ModalEditor
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmDeleteModal
        text={t("ArticleDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onDelete={handleDelete}
      />
    </>
  );
}

export default withTranslation()(FaqArticles);
