'use client';

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { OpTheme } from '../../providers';
import { ThemeSchema } from '../../providers/ThemeProvider/ThemeSchema';
import { typographyVarsMedias } from '../Typography/typographyVarsMedias';

export const LinkTheme = extendTheme({
  ...ThemeSchema,
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: OpTheme.typography.fontFamily,
          color: 'inherit',
          textDecoration: 'none',
          ...(ownerState.variant &&
            ownerState.variant !== 'inherit' && {
              fontSize: `var(--OP-typography-${[ownerState.variant]}-fontSize)`,
              fontWeight: `var(--OP-typography-${[ownerState.variant]}-fontWeight)`,
              lineHeight: `var(--OP-typography-${[ownerState.variant]}-lineHeight)`,
            }),
          ...typographyVarsMedias(),
        }),
      },
    },
  },
});
