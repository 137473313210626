import { create } from "./documentUtils";
import chevronDown from "./img/chevron-down.svg";

const COLORS = [
  "#ffffff",
  "#e0ebfd",
  "#eafbfe",
  "#e8fbf0",
  "#fefae8",
  "#fcece7",
  "#e9e6fd",
  "#f4f5f7",
  "#b9d4fb",
  "#c1f3fd",
  "#bbf3d3",
  "#fcf0ba",
  "#f5c0b0",
  "#beb7ee",
  "#b4bac4",
  "#5f9af8",
  "#93dfef",
  "#7cd5a7",
  "#f6c544",
  "#f0957a",
  "#978ed4",
];

export class CellMenu {
  constructor(table, config, api) {
    this.table = table;
    this._colors = config.colors || COLORS;
    this.api = api;

    /**
     * FIXME: 테이블 셀 메뉴와 컬러 팔레트가 무한 생성되는 현상을 수정해야 합니다.
     */
    this._init();
    this._fillCellMenu();
  }

  createElem(cell) {
    const openCellMenuButton = create("button", [CSS.openCellMenuButton]);
    const iconContainer = create("div", [CSS.iconBox]);

    iconContainer.innerHTML = chevronDown;

    openCellMenuButton.appendChild(iconContainer);
    openCellMenuButton.addEventListener(
      "mousedown",
      this._handleMouseDown.bind(this)
    );
    openCellMenuButton.addEventListener(
      "click",
      this._handleCellMenuButtonClick.bind(this)
    );
    cell.appendChild(openCellMenuButton);
  }

  _hideCellMenu(event) {
    if (event.target.closest("." + CSS.openCellMenuButton)) return;

    this.container.style.visibility = "hidden";

    this.table.body.querySelectorAll("." + CSS.iconBox).forEach((iconBox) => {
      iconBox.classList.remove("activated");
    });
  }

  _createMergeButton() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Объединить ячейки");

    option.classList.add(CSS.option);
    option.classList.add(CSS.mergeOption);
    option.addEventListener("click", this.mergeCells.bind(this));

    return option;
  }

  _createUnmergeButton() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Разделить ячейки");

    option.classList.add(CSS.option);
    option.classList.add(CSS.unmergeOption);
    option.addEventListener("click", this.unmerge.bind(this));

    return option;
  }

  _createAddColumnOnRightButton() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Вставить колонку справа");

    option.classList.add(CSS.option);
    option.addEventListener("click", this.addColumnOnRight.bind(this));

    return option;
  }

  _createAddRowBelow() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Вставить строку снизу");

    option.classList.add(CSS.option);
    option.addEventListener("click", this.addRowBelow.bind(this));

    return option;
  }

  _createColRemoveButton() {
    const option = document.createElement("button");

    const removeSelectedCols = (event) => {
      const focusedCell = this.selectedCell;
      const selectedCols = this.selectedCols;

      if (selectedCols.length === 0) {
        this.removeColumn(focusedCell.cellIndex);
        return;
      }

      selectedCols.forEach((row, i) => {
        this.removeColumn(row - i);
      });
    };

    option.textContent = this.api.i18n.t("Удалить колонку");

    option.classList.add(CSS.option);
    option.addEventListener("click", removeSelectedCols.bind(this));

    return option;
  }

  _createRowRemoveButton() {
    const option = document.createElement("button");

    const removeSelectedRows = (event) => {
      const focusedCell = this.selectedCell;
      const selectedRows = this.selectedRows;

      if (selectedRows.length === 0) {
        this.removeRow(focusedCell.parentNode.rowIndex);
        return;
      }

      selectedRows.forEach((row, i) => {
        this.removeRow(row - i);
      });
    };

    // 삭제할 행을 표시해주는 기능을 추가한다
    // https://stackoverflow.com/questions/49106088/changing-pseudo-element-style-from-javascript
    // const highlightCells = (event) => {
    // const focusedCell = this.selectedCell;
    // const selectedCells = this._table.querySelectorAll('.selected');
    // if (selectedCells.length === 0) {
    //   focusedCell.parentNode.classList.add(SelectLineCSS.trRemove);
    //   return;
    // }
    // const topLeftRow = selectedCells[0].parentNode.rowIndex;
    // const bottomRightRow = selectedCells[selectedCells.length - 1].parentNode.rowIndex;
    // for (let i = topLeftRow; i <= bottomRightRow; i++) {
    //   this._table.rows[i].classList.add(SelectLineCSS.trRemove);
    // }
    // }

    option.textContent = this.api.i18n.t("Удалить строку");

    option.classList.add(CSS.option);
    option.addEventListener("click", removeSelectedRows.bind(this));
    // option.addEventListener("mouseenter", highlightCells.bind(this))

    return option;
  }

  _createColorPickerButton() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Цвет ячейки");

    option.classList.add(CSS.option);
    option.classList.add(CSS.colorOption);

    option.addEventListener("mouseenter", (event) => {
      const { top, left } = option.getBoundingClientRect();
      const scrollY = Math.floor(window.scrollY);
      const { width } = this.colorPalette.getBoundingClientRect();

      this.colorPalette.style.top = `${Math.floor(top) + scrollY}px`;
      this.colorPalette.style.left = `${left - width}px`;
      this.colorPalette.style.visibility = "visible";
    });

    option.addEventListener("mouseleave", (event) => {
      this.colorPalette.style.visibility = "hidden";
    });

    return option;
  }

  _setTextAlign(align) {
    const selectedRows = this.table.selectedRows;
    const selectedCols = this.table.selectedCols;
    const selectedCell = this.table.selectedCell;

    if (!selectedRows.length && !selectedCols.length) {
      selectedCell.style.textAlign = align;
      this.table.deselectCells();
      return;
    }

    for (
      let i = selectedRows[0];
      i <= selectedRows[selectedRows.length - 1];
      i++
    ) {
      for (
        let j = selectedCols[0];
        j <= selectedCols[selectedCols.length - 1];
        j++
      ) {
        const cell = this.table.body.rows[i].cells[j];
        cell.style.textAlign = align;
      }
    }

    this.table.deselectCells();
    this.textAlignMenu.style.visibility = "hidden";
  }

  _createTextAlignButton() {
    const option = document.createElement("button");
    option.textContent = this.api.i18n.t("Ориентация текста");

    option.addEventListener("mouseenter", (event) => {
      const { top, left } = option.getBoundingClientRect();
      const scrollY = Math.floor(window.scrollY);
      const { width } = this.textAlignMenu.getBoundingClientRect();

      this.textAlignMenu.style.top = `${Math.floor(top) + scrollY}px`;
      this.textAlignMenu.style.left = `${left - width}px`;
      this._showTextAlignMenu();
    });

    option.addEventListener("mouseleave", (event) => {
      this._hideTextAlignMenu();
    });

    option.classList.add(CSS.option);

    return option;
  }

  _changeCellColor(event) {
    if (event.target.closest("." + CSS.colorBlock)) {
      const color =
        event.target.dataset.color === this._colors[0]
          ? null
          : event.target.dataset.color;
      const selectedRows = this.table.selectedRows;
      const selectedCols = this.table.selectedCols;
      const selectedCell = this.table.selectedCell;

      this.colorPalette.style.visibility = "hidden";
      this._hideCellMenu(event);

      if (!selectedRows.length && !selectedCols.length) {
        selectedCell.style.backgroundColor = color;
        this.table.deselectCells();
        return;
      }

      for (
        let i = selectedRows[0];
        i <= selectedRows[selectedRows.length - 1];
        i++
      ) {
        for (
          let j = selectedCols[0];
          j <= selectedCols[selectedCols.length - 1];
          j++
        ) {
          const cell = this.table.body.rows[i].cells[j];
          cell.style.backgroundColor = color;
        }
      }

      this.table.deselectCells();
    }
  }

  _hideColorPalette() {
    this.colorPalette.style.visibility = "hidden";
  }

  _showColorPalette() {
    this.colorPalette.style.visibility = "visible";
  }

  _hideTextAlignMenu() {
    this.textAlignMenu.style.visibility = "hidden";
  }

  _showTextAlignMenu() {
    this.textAlignMenu.style.visibility = "visible";
  }

  _createToggleFirstRowHeaderButton() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Заголовок строки");

    option.classList.add(CSS.option);
    option.classList.add(CSS.toggleRowHeaderOption);

    option.addEventListener("click", this.toggleFirstRowHeader.bind(this));

    return option;
  }

  _createToggleFirstColHeaderButton() {
    const option = document.createElement("button");

    option.textContent = this.api.i18n.t("Заголовок колонки");

    option.classList.add(CSS.option);
    option.classList.add(CSS.toggleColHeaderOption);

    option.addEventListener("click", this.toggleFirstColHeader.bind(this));

    return option;
  }

  _fillCellMenu() {
    const colorPickerButton = this._createColorPickerButton();
    const cellTextAlign = this._createTextAlignButton();
    // const toggleFirstRowHeaderButton = this._createToggleFirstRowHeaderButton.call(this.table);
    // const toggleFirstColHeaderButton = this._createToggleFirstColHeaderButton.call(this.table);
    const addColumnOnRightButton = this._createAddColumnOnRightButton.call(
      this.table
    );
    const addRowBelowButton = this._createAddRowBelow.call(this.table);
    const mergeButton = this._createMergeButton.call(this.table);
    // const unmergeButton = this._createUnmergeButton.call(this.table);
    const rowRemoveButton = this._createRowRemoveButton.call(this.table);
    const colRemoveButton = this._createColRemoveButton.call(this.table);

    this._cellMenuInner.appendChild(colorPickerButton);
    this._cellMenuInner.appendChild(cellTextAlign);
    // this._cellMenuInner.appendChild(toggleFirstRowHeaderButton);
    // this._cellMenuInner.appendChild(toggleFirstColHeaderButton);
    this._cellMenuInner.appendChild(addColumnOnRightButton);
    this._cellMenuInner.appendChild(addRowBelowButton);
    this._cellMenuInner.appendChild(mergeButton);
    // this._cellMenuInner.appendChild(unmergeButton);
    this._cellMenuInner.appendChild(rowRemoveButton);
    this._cellMenuInner.appendChild(colRemoveButton);
  }

  /**
   * 메뉴 창 닫기를 방지합니다.
   *
   * @param {MouseEvent} event
   */
  _handleMouseDown(event) {
    event.preventDefault();
  }

  _handleCellMenuButtonClick(event) {
    const openCellMenuButton = event.target.closest(
      "." + CSS.openCellMenuButton
    );
    const iconBox = openCellMenuButton.querySelector("." + CSS.iconBox);
    const mergeOption = this.container.querySelector("." + CSS.mergeOption);
    const unmergeOption = this.container.querySelector("." + CSS.unmergeOption);
    const toggleRowHeaderOption = this.container.querySelector(
      "." + CSS.toggleRowHeaderOption
    );
    const toggleColHeaderOption = this.container.querySelector(
      "." + CSS.toggleColHeaderOption
    );
    const { top, right } = iconBox.getBoundingClientRect();
    const scrollY = Math.floor(window.scrollY);
    const isMergePossible = this.table.checkIfMergePossible.call(this.table);
    const isCurrentCellMerged =
      this.table.selectedCell.colSpan > 1 ||
      this.table.selectedCell.rowSpan > 1;
    const isToggleRowHeaderPossible =
      this.table.selectedCell.parentNode.rowIndex === 0;
    const isToggleColHeaderPossible = this.table.selectedCell.cellIndex === 0;

    iconBox.classList.add("activated");

    this.container.style.top = `${Math.floor(top) + scrollY}px`;
    this.container.style.left = `${right + 4}px`;
    this.container.style.visibility = "visible";

    if (isToggleRowHeaderPossible) {
      toggleRowHeaderOption.style.display = "flex";

      this.table.isRowHeaderOn
        ? toggleRowHeaderOption.classList.add(CSS.rowHeaderOn)
        : toggleRowHeaderOption.classList.remove(CSS.rowHeaderOn);
    } else {
      toggleRowHeaderOption.style.display = "none";
    }

    if (isToggleColHeaderPossible) {
      toggleColHeaderOption.style.display = "flex";

      this.table.isColHeaderOn
        ? toggleColHeaderOption.classList.add(CSS.colHeaderOn)
        : toggleColHeaderOption.classList.remove(CSS.colHeaderOn);
    } else {
      toggleColHeaderOption.style.display = "none";
    }

    if (isMergePossible) {
      mergeOption.disabled = false;
    } else {
      mergeOption.disabled = true;
    }

    if (isCurrentCellMerged) {
      unmergeOption.style.display = "flex";
    } else {
      unmergeOption.style.display = "none";
    }
  }

  _createTextAlignMenu() {
    const menu = document.createElement("div");
    const taLeftOption = document.createElement("div");
    const taRightOption = document.createElement("div");
    const taCenterOption = document.createElement("div");
    taLeftOption.textContent = "Слева";
    taRightOption.textContent = "Справа";
    taCenterOption.textContent = "По центру";
    taLeftOption.onclick = () => this._setTextAlign("left");
    taRightOption.onclick = () => this._setTextAlign("right");
    taCenterOption.onclick = () => this._setTextAlign("center");
    taLeftOption.classList.add("tc-table__option_table__inner__option");
    taRightOption.classList.add("tc-table__option_table__inner__option");
    taCenterOption.classList.add("tc-table__option_table__inner__option");

    menu.classList.add(CSS.textAlignMenu);
    menu.appendChild(taLeftOption);
    menu.appendChild(taRightOption);
    menu.appendChild(taCenterOption);

    // function showTextAlignMenu() {
    //   menu.style.visibility = "visible"
    //   console.info(2);
    // }

    // function hideTextAlignMenu() {
    //   menu.style.visibility = "hide"
    //   console.info(3);
    // }

    // menu.addEventListener("mouseenter", showTextAlignMenu)
    // menu.addEventListener("mouseleave", hideTextAlignMenu)

    return menu;
  }

  _init() {
    const cellMenuInner = document.createElement("div");
    cellMenuInner.classList.add(CSS.cellMenuInner);

    const cellMenu = document.createElement("div");
    cellMenu.appendChild(cellMenuInner);
    cellMenu.classList.add(CSS.cellMenu);

    const colorPalette = document.createElement("div");
    colorPalette.classList.add(CSS.colorPalette);

    this._colors.forEach((color) => {
      const colorBlock = document.createElement("figure");
      colorBlock.style.backgroundColor = color;
      colorBlock.classList.add(CSS.colorBlock);
      colorBlock.setAttribute("data-color", color);
      colorPalette.appendChild(colorBlock);
    });

    this.container = cellMenu;
    this.colorPalette = colorPalette;
    this._cellMenuInner = cellMenuInner;
    this.textAlignMenu = this._createTextAlignMenu();

    this._cellMenuInner.addEventListener(
      "click",
      this._catchClickEventDelegation.bind(this)
    );
    this.colorPalette.addEventListener(
      "click",
      this._changeCellColor.bind(this)
    );
    this.colorPalette.addEventListener(
      "mouseenter",
      this._showColorPalette.bind(this)
    );
    this.colorPalette.addEventListener(
      "mouseleave",
      this._hideColorPalette.bind(this)
    );
    this.textAlignMenu.addEventListener(
      "mouseenter",
      this._showTextAlignMenu.bind(this)
    );
    this.textAlignMenu.addEventListener(
      "mouseleave",
      this._hideTextAlignMenu.bind(this)
    );

    document.body.appendChild(cellMenu);
    document.body.appendChild(colorPalette);
    document.body.appendChild(this.textAlignMenu);
    document.addEventListener("click", this._hideCellMenu.bind(this));
  }

  _catchClickEventDelegation(event) {
    if (event.target.classList.contains(CSS.option)) {
      this._hideCellMenu(event);
    }
  }
}

export const CSS = {
  openCellMenuButton: "tc-table__option_button",
  iconBox: "tc-table__option_button__inner_box",
  cellMenu: "tc-table__option_table",
  cellMenuInner: "tc-table__option_table__inner",
  option: "tc-table__option_table__inner__option",
  mergeOption: "merge-option",
  unmergeOption: "unmerge-option",
  colorOption: "color-option",
  colorPalette: "tc-table__color-palette",
  colorBlock: "color-block",
  toggleRowHeaderOption: "toggle-row-header-option",
  toggleColHeaderOption: "toggle-col-header-option",
  rowHeaderOn: "row-header-on",
  colHeaderOn: "col-header-on",
  textAlignMenu: "tc-table__text-align-menu",
};
