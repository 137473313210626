import { $api, rtkApi } from '@op/api';
import { COOKIE_SESSION_KEY, getCookie } from '@op/ui';
import { AxiosResponse } from 'axios';

import { API_PROMO_LEGACY_URL } from '../../Env';
import { IMktuDetailRequest, IMktuDetailResponse } from '../model/types/mktuDetail';

const mktuDetailOptions = ({ id, ...params }: IMktuDetailRequest) => ({
  // url: `${API_PROMO_URL}/mktuClass/${id}`, // future API
  url: `${API_PROMO_LEGACY_URL}/api/promo/static/MktuClasses/${id}.json`,
  params: {
    ...params,
    session_key: getCookie(COOKIE_SESSION_KEY),
  },
});

const mktuDetailApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getMktuDetail: build.query<IMktuDetailResponse, IMktuDetailRequest>({
      query: mktuDetailOptions,
    }),
  }),
});

export const useGetMktuDetail = mktuDetailApi.useGetMktuDetailQuery;
export const useLazyGetMktuDetail = mktuDetailApi.useLazyGetMktuDetailQuery;

export const getMktuDetail = (params: IMktuDetailRequest): Promise<AxiosResponse<IMktuDetailResponse>> =>
  $api.request(mktuDetailOptions(params));
