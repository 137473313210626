'use client';

import { buttons } from './buttons/buttons';

export const hoverArrowAngleAnimate = {
  animation: 'arrowAngleHover 0.6s cubic-bezier(0.58, -0.3, 0.64, 1.18) forwards',
  '@keyframes arrowAngleHover': {
    '48%': {
      transform: 'translate(100%, -100%)',
    },
    '49%': {
      transform: 'translate(-100%, 100%)',
      animationDuration: '0s',
    },
    '50%': {
      transform: 'translate(-100%, 100%)',
    },
    '100%': {
      transform: 'translate(0, 0)',
    },
  },
};

export const hoverScaleUp = {
  transform: `scale(${buttons.scaleUp.hover})`,
};
export const activeScaleUp = {
  transform: `scale(${buttons.scaleUp.active})`,
};
