import { Link as RouterLink } from "react-router-dom";

export default function Link({
  to,
  className,
  children,
  as,
  ...rest
}: {
  to: string;
  children?: any;
  className?: string;
  as?: string;
  target?: string;
  rel?: string;
}) {
  return (
    <RouterLink to={to} className={className} {...rest}>
      {children}
    </RouterLink>
  );
}
