import { typography } from '../../consts';
import { OpTheme } from '../../providers';
import { pxToVwLarge, pxToVwSmall, transformPxToVwInStyleObject } from '../../utils';

export const typographyVarsMedias = () => {
  const vars = {
    '--OP-typography-hero-fontSize': typography.hero.fontSize,
    '--OP-typography-h2-fontSize': typography.h2.fontSize,
    '--OP-typography-h3-fontSize': typography.h3.fontSize,
    '--OP-typography-h4-fontSize': typography.h4.fontSize,
    '--OP-typography-h5-fontSize': typography.h5.fontSize,
    '--OP-typography-h6-fontSize': typography.h6.fontSize,
    '--OP-typography-subtitle1-fontSize': typography.subtitle1.fontSize,
    '--OP-typography-subtitle2-fontSize': typography.subtitle2.fontSize,
    '--OP-typography-body1-fontSize': typography.body1.fontSize,
    '--OP-typography-body2-fontSize': typography.body2.fontSize,
    '--OP-typography-links-fontSize': typography.links.fontSize,
    '--OP-typography-small-fontSize': typography.small.fontSize,
    '--OP-typography-caption-fontSize': typography.caption.fontSize,
    '--OP-typography-button-fontSize': typography.button.fontSize,
    '--OP-typography-buttonSmall-fontSize': typography.buttonSmall.fontSize,
  };

  const varsSmall = transformPxToVwInStyleObject(vars, OpTheme.breakpoints.values.desktopSmall);

  const varsLarge = transformPxToVwInStyleObject(vars, OpTheme.breakpoints.values.desktopLarge);

  return {
    ...vars,
    '--OP-typography-hero-fontWeight': typography.hero.fontWeight,
    '--OP-typography-hero-lineHeight': typography.hero.lineHeight,
    '--OP-typography-hero-letterSpacing': `calc(${typography.hero.letterSpacing} * -1)`,
    '--OP-typography-h1-fontSize': typography.h1.fontSize,
    '--OP-typography-h1-fontWeight': typography.h1.fontWeight,
    '--OP-typography-h1-lineHeight': typography.h1.lineHeight,
    '--OP-typography-h1-letterSpacing': `calc(${typography.h1.letterSpacing} * -1)`,
    '--OP-typography-h2-fontWeight': typography.h2.fontWeight,
    '--OP-typography-h2-lineHeight': typography.h2.lineHeight,
    '--OP-typography-h2-letterSpacing': `calc(${typography.h2.letterSpacing} * -1)`,
    '--OP-typography-h3-fontWeight': typography.h3.fontWeight,
    '--OP-typography-h3-lineHeight': typography.h3.lineHeight,
    '--OP-typography-h3-letterSpacing': `calc(${typography.h3.letterSpacing} * -1)`,
    '--OP-typography-h4-fontWeight': typography.h4.fontWeight,
    '--OP-typography-h4-lineHeight': typography.h4.lineHeight,
    '--OP-typography-h4-letterSpacing': `calc(${typography.h4.letterSpacing} * -1)`,
    '--OP-typography-h5-fontWeight': typography.h5.fontWeight,
    '--OP-typography-h5-lineHeight': typography.h5.lineHeight,
    '--OP-typography-h5-letterSpacing': `calc(${typography.h5.letterSpacing} * -1)`,
    '--OP-typography-h6-fontWeight': typography.h6.fontWeight,
    '--OP-typography-h6-lineHeight': typography.h6.lineHeight,
    '--OP-typography-h6-letterSpacing': `calc(${typography.h6.letterSpacing} * -1)`,
    '--OP-typography-subtitle1-fontWeight': typography.subtitle1.fontWeight,
    '--OP-typography-subtitle1-lineHeight': typography.subtitle1.lineHeight,
    '--OP-typography-subtitle1-letterSpacing': `calc(${typography.subtitle1.letterSpacing} * -1)`,
    '--OP-typography-subtitle2-fontWeight': typography.subtitle2.fontWeight,
    '--OP-typography-subtitle2-lineHeight': typography.subtitle2.lineHeight,
    '--OP-typography-subtitle2-letterSpacing': `calc(${typography.subtitle2.letterSpacing} * -1)`,
    '--OP-typography-body1-fontWeight': typography.body1.fontWeight,
    '--OP-typography-body1-lineHeight': typography.body1.lineHeight,
    '--OP-typography-body1-letterSpacing': `calc(${typography.body1.letterSpacing} * -1)`,
    '--OP-typography-body2-fontWeight': typography.body2.fontWeight,
    '--OP-typography-body2-lineHeight': typography.body2.lineHeight,
    '--OP-typography-body2-letterSpacing': `calc(${typography.body2.letterSpacing} * -1)`,
    '--OP-typography-links-fontWeight': typography.links.fontWeight,
    '--OP-typography-links-lineHeight': typography.links.lineHeight,
    '--OP-typography-links-letterSpacing': `calc(${typography.links.letterSpacing} * -1)`,
    '--OP-typography-small-fontWeight': typography.small.fontWeight,
    '--OP-typography-small-lineHeight': typography.small.lineHeight,
    '--OP-typography-small-letterSpacing': `calc(${typography.small.letterSpacing} * -1)`,
    '--OP-typography-caption-fontWeight': typography.caption.fontWeight,
    '--OP-typography-caption-lineHeight': typography.caption.lineHeight,
    '--OP-typography-caption-letterSpacing': `calc(${typography.caption.letterSpacing} * -1)`,
    '--OP-typography-button-fontWeight': typography.button.fontWeight,
    '--OP-typography-button-lineHeight': typography.button.lineHeight,
    '--OP-typography-button-letterSpacing': `calc(${typography.button.letterSpacing} * -1)`,
    '--OP-typography-buttonSmall-fontWeight': typography.buttonSmall.fontWeight,
    '--OP-typography-buttonSmall-lineHeight': typography.buttonSmall.lineHeight,
    '--OP-typography-buttonSmall-letterSpacing': `calc(${typography.buttonSmall.letterSpacing} * -1)`,
    [OpTheme.breakpoints.between('tabletStart', 'desktopSmall')]: {
      '--OP-typography-hero-letterSpacing': `calc(${pxToVwSmall(typography.hero.letterSpacing)} * -1)`,
      '--OP-typography-h1-fontSize': pxToVwSmall(typography.h1.fontSize),
      '--OP-typography-h1-letterSpacing': `calc(${pxToVwSmall(typography.h1.letterSpacing)} * -1)`,
      '--OP-typography-h2-letterSpacing': `calc(${pxToVwSmall(typography.h2.letterSpacing)} * -1)`,
      '--OP-typography-h3-letterSpacing': `calc(${pxToVwSmall(typography.h3.letterSpacing)} * -1)`,
      '--OP-typography-h4-letterSpacing': `calc(${pxToVwSmall(typography.h4.letterSpacing)} * -1)`,
      '--OP-typography-h5-letterSpacing': `calc(${pxToVwSmall(typography.h5.letterSpacing)} * -1)`,
      '--OP-typography-h6-letterSpacing': `calc(${pxToVwSmall(typography.h6.letterSpacing)} * -1)`,
      '--OP-typography-subtitle1-letterSpacing': `calc(${pxToVwSmall(typography.subtitle1.letterSpacing)} * -1)`,
      '--OP-typography-subtitle2-letterSpacing': `calc(${pxToVwSmall(typography.subtitle2.letterSpacing)} * -1)`,
      '--OP-typography-body1-letterSpacing': `calc(${pxToVwSmall(typography.body1.letterSpacing)} * -1)`,
      '--OP-typography-body2-letterSpacing': `calc(${pxToVwSmall(typography.body2.letterSpacing)} * -1)`,
      '--OP-typography-links-letterSpacing': `calc(${pxToVwSmall(typography.links.letterSpacing)} * -1)`,
      '--OP-typography-small-letterSpacing': `calc(${pxToVwSmall(typography.small.letterSpacing)} * -1)`,
      '--OP-typography-caption-letterSpacing': `calc(${pxToVwSmall(typography.caption.letterSpacing)} * -1)`,
      '--OP-typography-button-letterSpacing': `calc(${pxToVwSmall(typography.button.letterSpacing)} * -1)`,
      '--OP-typography-buttonSmall-letterSpacing': `calc(${pxToVwSmall(typography.buttonSmall.letterSpacing)} * -1)`,
      ...varsSmall,
    },
    [OpTheme.breakpoints.up('desktopLarge')]: {
      '--OP-typography-hero-letterSpacing': `calc(${pxToVwLarge(typography.hero.letterSpacing)} * -1)`,
      '--OP-typography-h1-fontSize': pxToVwLarge(92),
      '--OP-typography-h1-letterSpacing': `calc(${pxToVwLarge(typography.hero.letterSpacing)} * -1)`,
      '--OP-typography-h2-letterSpacing': `calc(${pxToVwLarge(typography.h2.letterSpacing)} * -1)`,
      '--OP-typography-h3-letterSpacing': `calc(${pxToVwLarge(typography.h3.letterSpacing)} * -1)`,
      '--OP-typography-h4-letterSpacing': `calc(${pxToVwLarge(typography.h4.letterSpacing)} * -1)`,
      '--OP-typography-h5-letterSpacing': `calc(${pxToVwLarge(typography.h5.letterSpacing)} * -1)`,
      '--OP-typography-h6-letterSpacing': `calc(${pxToVwLarge(typography.h6.letterSpacing)} * -1)`,
      '--OP-typography-subtitle1-letterSpacing': `calc(${pxToVwLarge(typography.subtitle1.letterSpacing)} * -1)`,
      '--OP-typography-subtitle2-letterSpacing': `calc(${pxToVwLarge(typography.subtitle2.letterSpacing)} * -1)`,
      '--OP-typography-body1-letterSpacing': `calc(${pxToVwLarge(typography.body1.letterSpacing)} * -1)`,
      '--OP-typography-body2-letterSpacing': `calc(${pxToVwLarge(typography.body2.letterSpacing)} * -1)`,
      '--OP-typography-links-letterSpacing': `calc(${pxToVwLarge(typography.links.letterSpacing)} * -1)`,
      '--OP-typography-small-letterSpacing': `calc(${pxToVwLarge(typography.small.letterSpacing)} * -1)`,
      '--OP-typography-caption-letterSpacing': `calc(${pxToVwLarge(typography.caption.letterSpacing)} * -1)`,
      '--OP-typography-button-letterSpacing': `calc(${pxToVwLarge(typography.button.letterSpacing)} * -1)`,
      '--OP-typography-buttonSmall-letterSpacing': `calc(${pxToVwLarge(typography.buttonSmall.letterSpacing)} * -1)`,
      ...varsLarge,
    },
    [OpTheme.breakpoints.down('mobile')]: {
      '--OP-typography-hero-letterSpacing': `calc(${typography.hero.letterSpacing} * -1)`,
      '--OP-typography-h1-letterSpacing': `calc(${typography.h1.letterSpacing} * -1)`,
      '--OP-typography-h2-letterSpacing': `calc(${typography.h2.letterSpacing} * -1)`,
      '--OP-typography-h3-letterSpacing': `calc(${typography.h3.letterSpacing} * -1)`,
      '--OP-typography-h4-letterSpacing': `calc(${typography.h4.letterSpacing} * -1)`,
      '--OP-typography-h5-letterSpacing': `calc(${typography.h5.letterSpacing} * -1)`,
      '--OP-typography-h6-letterSpacing': `calc(${typography.h6.letterSpacing} * -1)`,
      '--OP-typography-subtitle1-letterSpacing': `calc(${typography.subtitle1.letterSpacing} * -1)`,
      '--OP-typography-subtitle2-letterSpacing': `calc(${typography.subtitle2.letterSpacing} * -1)`,
      '--OP-typography-body1-letterSpacing': `calc(${typography.body1.letterSpacing} * -1)`,
      '--OP-typography-body2-letterSpacing': `calc(${typography.body2.letterSpacing} * -1)`,
      '--OP-typography-links-letterSpacing': `calc(${typography.links.letterSpacing} * -1)`,
      '--OP-typography-small-letterSpacing': `calc(${typography.small.letterSpacing} * -1)`,
      '--OP-typography-caption-letterSpacing': `calc(${typography.caption.letterSpacing} * -1)`,
      '--OP-typography-button-letterSpacing': `calc(${typography.button.letterSpacing} * -1)`,
      '--OP-typography-buttonSmall-letterSpacing': `calc(${typography.buttonSmall.letterSpacing} * -1)`,
    },
  };
};
