import moment from "moment";

import { DATE_FORMAT_UTC } from "../../consts/common";

export const utcOffsetDateString = (dateString = "") => {
  const diff = moment().utcOffset();
  const dateUTC = moment
    .utc(dateString)
    .utcOffset(diff)
    .format(DATE_FORMAT_UTC);
  return `${dateUTC}${diff < 0 ? "-" : "+"}${moment(diff).format("HH")}`;
};
