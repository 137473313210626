'use client';

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { SvgIconVarsMedias } from './SvgIconVarsMedias';

export const SvgIconTheme = extendTheme({
  components: {
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'medium',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontSize: 0,
          ...(ownerState.fontSize &&
            ownerState.fontSize === 'inherit' && {
              width: 'var(--OP-svgIcon-size-width)',
              height: 'var(--OP-svgIcon-size-height)',
            }),
          ...(ownerState.fontSize &&
            ownerState.fontSize === 'small' && {
              width: 'var(--OP-svgIcon-size-small)',
              height: 'var(--OP-svgIcon-size-small)',
            }),
          ...(ownerState.fontSize &&
            ownerState.fontSize === 'medium' && {
              width: 'var(--OP-svgIcon-size-medium)',
              height: 'var(--OP-svgIcon-size-medium)',
            }),
          ...(ownerState.fontSize &&
            ownerState.fontSize === 'large' && {
              width: 'var(--OP-svgIcon-size-large)',
              height: 'var(--OP-svgIcon-size-large)',
            }),
          ...SvgIconVarsMedias(),
        }),
      },
    },
  },
});
