import React, { useMemo } from "react";

import authorStore from "../app/providers/MobxStore/model/AuthorStore";
import Author from "./Authors/Author";

interface ICardArticleInfo {
  title: string;
  authorId: number;
  articleIndex: string;
}

const CardArticleInfo = ({
  title,
  authorId,
  articleIndex,
}: ICardArticleInfo) => {
  const author = useMemo(
    () => authorStore.data.find((author) => author.author_id === authorId),
    [authorId]
  );

  return (
    <>
      <p className="profile-article__title">{title}</p>
      <div className="profile-article__info">
        <div className="profile-article__info-item">
          {author && (
            <Author
              name={author.name}
              initials={author.initials}
              avatar={author.avatar}
            />
          )}
        </div>
        <div className="profile-article__info-item">
          <span className="profile-article__url">{articleIndex}</span>
        </div>
      </div>
    </>
  );
};

export default CardArticleInfo;
