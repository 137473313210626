import ImageTool from "../../vendor/image/dist/bundle";

// https://github.com/editor-js/image

type dataType = {
  text: string;
  author: string;
  role: string;
  imageUrl: string;
};
export default class Quote extends ImageTool {
  textPlaceholder: string;
  authorPlaceholder: string;
  rolePlaceholder: string;

  static DEFAULT_TEXT_PLACEHOLDER: string;
  static DEFAULT_AUTHOR_PLACEHOLDER: string;
  static DEFAULT_ROLE_PLACEHOLDER: string;

  constructor(props: IEditor<dataType>) {
    super(props);
    const { config, data } = props;
    this.textPlaceholder =
      config.textPlaceholder || Quote.DEFAULT_TEXT_PLACEHOLDER;
    this.authorPlaceholder =
      config.authorPlaceholder || Quote.DEFAULT_AUTHOR_PLACEHOLDER;
    this.rolePlaceholder =
      config.rolePlaceholder || Quote.DEFAULT_ROLE_PLACEHOLDER;

    this._data = {
      ...this.data,
      text: data?.text || "",
      author: data?.author || "",
      role: data?.role || "",
    };
  }
  static get toolbox() {
    return {
      icon: '<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6667 0C9.68403 0 8.88889 0.79514 8.88889 1.77778V5.33333C8.88889 6.31597 9.68403 7.11111 10.6667 7.11111H13.3438L10.4097 11.0521C9.97569 11.6354 10.3924 12.4688 11.125 12.4688C11.4028 12.4688 11.6667 12.3368 11.8333 12.1111L15.6493 6.98611C15.8785 6.68056 16 6.30903 16 5.92708V1.77778C16 0.79514 15.2049 0 14.2222 0H10.6667ZM1.77778 0.024306C0.795139 0.024306 0 0.819445 0 1.80208V5.35764C0 6.34028 0.795139 7.13542 1.77778 7.13542H4.45139L1.53819 11.0486C1.10417 11.6354 1.52083 12.4688 2.25 12.4688C2.53125 12.4688 2.79861 12.3368 2.96528 12.1111L6.76042 7.01042C6.98958 6.70486 7.11111 6.33333 7.11111 5.95139V1.80208C7.11111 0.819445 6.31597 0.024306 5.33333 0.024306H1.77778Z" fill="black"/></svg>',
      title: "Quote",
    };
  }

  get CSS(): Record<string, string> {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-quote",
      text: "cdx-quote__text",
      input: this.api.styles.input,
      author: "cdx-quote__author",
      role: "cdx-quote__role",
      settingsWrapper: "cdx-quote-settings",
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  appendCallback() {}

  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);
    const text = this._make("div", [this.CSS.input, this.CSS.text], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.text,
    });

    const imageTool = this.ui.render(this.data);

    const author = this._make("div", [this.CSS.input, this.CSS.author], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.author,
    });

    const role = this._make("div", [this.CSS.input, this.CSS.role], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.role,
    });

    const imageContainer = this._make("div", ["cdx-quote__image-container"]);

    text.dataset.placeholder = this.textPlaceholder;
    author.dataset.placeholder = this.authorPlaceholder;
    role.dataset.placeholder = this.rolePlaceholder;

    container.appendChild(text);
    container.appendChild(imageContainer);
    imageContainer.appendChild(imageTool);
    imageContainer.appendChild(author);
    container.appendChild(role);

    return container;
  }
  save(quoteElement: HTMLDivElement) {
    const text = quoteElement.querySelector(`.${this.CSS.text}`);
    const author = quoteElement.querySelector(`.${this.CSS.author}`);
    const role = quoteElement.querySelector(`.${this.CSS.role}`);
    return Object.assign(this.data, {
      text: text?.innerHTML,
      image: this.data.file,
      author: author?.innerHTML,
      role: role?.innerHTML,
    });
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
      alignment: {},
    };
  }

  _make(
    tagName: string,
    classNames: string[] = [],
    attributes: Record<string, any> = {}
  ) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      (el as any)[attrName] = attributes[attrName];
    }

    return el;
  }
}
