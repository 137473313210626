import { fontWeightBase } from './fonts';

export const typography = {
  hero: {
    fontSize: '92px',
    fontWeight: fontWeightBase,
    lineHeight: 1,
    letterSpacing: '1.92px',
  },
  h1: {
    fontSize: '60px',
    fontWeight: fontWeightBase,
    lineHeight: 1,
    letterSpacing: '1.28px',
  },
  h2: {
    fontSize: '60px',
    fontWeight: fontWeightBase,
    lineHeight: 1.05,
    letterSpacing: '1.28px',
  },
  h3: {
    fontSize: '44px',
    fontWeight: fontWeightBase,
    lineHeight: 1.05,
    letterSpacing: '0.96px',
  },
  h4: {
    fontSize: '38px',
    fontWeight: fontWeightBase,
    lineHeight: 0.95,
    letterSpacing: '0.4px',
  },
  h5: {
    fontSize: '30px',
    fontWeight: fontWeightBase,
    lineHeight: 1.05,
    letterSpacing: '0.32px',
  },
  h6: {
    fontSize: '22px',
    fontWeight: fontWeightBase,
    lineHeight: 1.05,
    letterSpacing: '0.32px',
  },
  subtitle1: {
    fontSize: '18px',
    fontWeight: fontWeightBase,
    lineHeight: 1.05,
    letterSpacing: '0.32px',
  },
  subtitle2: {
    fontSize: '22px',
    fontWeight: fontWeightBase,
    lineHeight: 1.16,
    letterSpacing: '0.24px',
  },
  body1: {
    fontSize: '22px',
    fontWeight: fontWeightBase,
    lineHeight: 1,
    letterSpacing: '0px',
  },
  body2: {
    fontSize: '16px',
    fontWeight: fontWeightBase,
    lineHeight: 1.3,
    letterSpacing: '0px',
  },
  links: {
    fontSize: '18px',
    fontWeight: fontWeightBase,
    lineHeight: 1.2,
    letterSpacing: '0px',
  },
  small: {
    fontSize: '14px',
    fontWeight: fontWeightBase,
    lineHeight: 1,
    letterSpacing: '0px',
  },
  caption: {
    fontSize: '12px',
    fontWeight: fontWeightBase,
    lineHeight: 1.35,
    letterSpacing: '0px',
  },
  button: {
    fontSize: '16px',
    fontWeight: fontWeightBase,
    lineHeight: 1,
    letterSpacing: '0.5px',
  },
  buttonSmall: {
    fontSize: '14px',
    fontWeight: fontWeightBase,
    lineHeight: 1,
    letterSpacing: '0.5px',
  },
};
