import classNames from "classnames";

interface IAuthor {
  name?: string;
  initials?: string;
  className?: string;
  avatar?: string;
}

const Author = ({ name, initials, className, avatar }: IAuthor) => {
  return (
    <div className={classNames(["author", className && className])}>
      {avatar && (
        <div className="author__avatar-block">
          <span
            className="author__avatar"
            style={{ backgroundImage: `url(${avatar})` }}
          />
        </div>
      )}

      {initials && !avatar && (
        <div className="author__avatar-block">
          <span className="author__avatar-initials">{initials}</span>
        </div>
      )}

      {name && (
        <div className="author__data">
          <span className="author__name">{name}</span>
        </div>
      )}
    </div>
  );
};

export default Author;
