import UserStore from "@/app/providers/MobxStore/model/UserStore";
import { getConfig } from "@/shared/lib/utils/getConfig";
import Link from "@/shared/ui/Link";

const { REACT_APP_ASSETS_URL } = getConfig();

export const Error500 = () => {
  // eslint-disable-next-line no-empty-pattern
  const {} = UserStore; // todo: resolve this weird

  return (
    <div className="main-columns error-page">
      <div className="main-columns__center">
        <div className="wrap">
          <div className="error-page__holder error-page__holder_pic-admin">
            <div className="error-page__title-block">
              <p className="prime-title error-page__title desktop-only">500</p>
              <p className="error-page__subtitle">что-то сломалось</p>
              <p className="prime-title error-page__title error-page__title_pic">
                Код ошибки: 500
              </p>

              <Link
                to="/"
                className="btn btn-fond size-xl blue lighten-up scale-up"
              >
                <span className="icon icon-arrow-btn btn__icon" />
                <i className="btn__text">На главную</i>
              </Link>
            </div>
            <div className="error-page__pic-block error-page__pic-block_admin">
              <img
                alt=""
                className="error-page__pic"
                src={`${REACT_APP_ASSETS_URL}/images/journal/500.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
