export enum ETags {
  farma = "farma",
  tovarnie_znaki = "tovarnie_znaki",
  analitika = "analitika",
  novosti = "novosti",
  sudi_i_patentnie_spori = "sudi_i_patentnie_spori",
  tehnologii = "tehnologii",
  all = "all",
}

export const PROMO_LINKS = [
  {
    title: "Товарный знак",
    link: "https://onlinepatent.ru/promo/trademark/",
  },
  {
    title: "Программа для\u00a0ЭВМ",
    link: "https://onlinepatent.ru/promo/software/",
  },
  {
    title: "Полезная модель",
    link: "https://onlinepatent.ru/promo/patent/",
  },
  {
    title: "Изобретение",
    link: "https://onlinepatent.ru/promo/patent/",
  },
  {
    title: "Дизайн изделия",
    link: "https://onlinepatent.ru/promo/design/",
  },
  {
    title: "База данных",
    link: "https://onlinepatent.ru/promo/database/",
  },
];

export const SERVICE_LINKS = [
  {
    title: "Товарные знаки",
    link: "https://onlinepatent.ru/service/trademark/?q=",
  },
  {
    title: "Программы для\u00a0ЭВМ",
    link: "https://onlinepatent.ru/service/software/?q=",
  },
  {
    title: "База данных",
    link: "https://onlinepatent.ru/service/database/",
  },
  {
    title: "Промышленные образцы",
    link: "https://onlinepatent.ru/service/design/",
  },
  {
    title: "Изобретения, полезные модели",
    link: "https://onlinepatent.ru/service/patent/",
  },
];

export const DATE_FORMAT_UTC = "YYYY-MM-DDTHH:mm:ss";
