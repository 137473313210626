import { Modal } from "@/features/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

interface IConfirmDeleteModal {
  text?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmCloseModal = ({
  open,
  onClose,
  onConfirm,
  text,
}: IConfirmDeleteModal) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="confirm-modal"
      canBeExpanded={false}
      open={open}
      onClose={onClose}
    >
      <div className="confirm-modal__body">
        <strong className="confirm-modal__title">{t("Close")}</strong>
        <p className="confirm-modal__text">{text || t("DefaultConfirm")}</p>
        <div className="confirm-modal__btns">
          <button
            className="btn btn-ghost ghost confirm-modal__btn"
            onClick={onClose}
          >
            <span className="btn__text">{t("Cancel")}</span>
          </button>
          <button
            className="btn btn-fond  red confirm-modal__btn"
            onClick={onConfirm}
          >
            <span className="btn__text">{t("Close")}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};
