import _ from "lodash";

export enum EVoteStatus {
  isNew,
  wasEdited,
}

export interface TVoteData extends VoteResponse {
  status?: EVoteStatus;
}

export default class Vote {
  api: API;
  readOnly: boolean = false;
  data: TVoteData;
  titlePlaceholder: string;
  answerPlaceholder: string;

  static DEFAULT_TITLE_PLACEHOLDER: string;
  static DEFAULT_ANSWER_PLACEHOLDER: string;

  constructor({ data, config, api }: IEditor<TVoteData>) {
    this.api = api;

    this.titlePlaceholder =
      config.titlePlaceholder || Vote.DEFAULT_TITLE_PLACEHOLDER;

    this.answerPlaceholder =
      config.answerPlaceholder || Vote.DEFAULT_ANSWER_PLACEHOLDER;

    this.data = _.isEmpty(data)
      ? {
          title: "",
          status: EVoteStatus.isNew,
          answers: [
            { answer_id: 0, label: "", counter: 0 },
            { answer_id: 1, label: "", counter: 0 },
            { answer_id: 2, label: "", counter: 0 },
          ],
        }
      : data!;
  }

  static get toolbox() {
    return {
      icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.60938 0H14.4C15.2812 0 16 0.71875 16 1.6V11.2C16 12.0813 15.2812 12.8 14.4 12.8H7.53125C7.31875 12.8 7.11562 12.8844 6.96562 13.0344L4.56563 15.4344C4.0625 15.9375 3.2 15.5812 3.2 14.8687V13.2C3.2 12.9781 3.02188 12.8 2.8 12.8H1.6C0.715625 12.8 0 12.0844 0 11.2L0.00937525 1.6C0.00937525 0.71875 0.728125 0 1.60938 0ZM7.80723 5.98154C8.28804 5.61409 8.75333 5.25849 8.75333 4.54666C8.75333 3.98266 8.27333 3.51466 7.62533 3.51466C6.96533 3.51466 6.53333 3.77866 6.14933 4.28266L5.33333 3.35866C5.76533 2.66266 6.62933 2.13466 7.70933 2.13466C9.30533 2.13466 10.2293 3.09466 10.2293 4.24666C10.2293 5.51541 9.52398 6.0823 8.91381 6.57268C8.39284 6.99137 7.94127 7.35429 8.05733 8.05066L6.79733 8.06266C6.60572 6.89977 7.21812 6.43175 7.80723 5.98154ZM7.48133 10.7267C6.89333 10.7267 6.53333 10.2587 6.53333 9.76666C6.53333 9.34666 6.89333 8.87866 7.48133 8.87866C8.00933 8.87866 8.36933 9.34666 8.36933 9.76666C8.36933 10.2587 8.00933 10.7267 7.48133 10.7267Z" fill="black"/></svg>',
      title: "Vote",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  get CSS(): Record<string, string> {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-vote",
      title: "cdx-vote__title",
      input: this.api.styles.input,
      answer: "cdx-vote__btn",
      settingsWrapper: "cdx-vote-settings",
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);

    const title = this._make("div", [this.CSS.input, this.CSS.title], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.title,
    });

    title.dataset.placeholder = this.titlePlaceholder;

    container.appendChild(title);
    this.data.answers.forEach((answer) => {
      const answerDiv = this._make(
        "div",
        [this.CSS.input, this.CSS.answer, "voteAnswer-" + answer.answer_id],
        {
          contentEditable: !this.readOnly,
          innerHTML: answer.label || "",
        }
      );
      answerDiv.dataset.placeholder = this.answerPlaceholder;
      container.appendChild(answerDiv);

      const handleKeyDown = () => {
        if (typeof this.data.status === "undefined") {
          this.data.status = EVoteStatus.wasEdited;
        }
        container.removeEventListener("keydown", handleKeyDown);
      };

      container.addEventListener("keydown", handleKeyDown);
    });

    return container;
  }

  save(voteElement: HTMLDivElement): TVoteData {
    const title = voteElement.querySelector(`.${this.CSS.title}`);

    const answers = this.data.answers.map((answer) => {
      return {
        ...answer,
        label: voteElement.querySelector(`.voteAnswer-${answer.answer_id}`)
          ?.innerHTML,
      };
    });
    return Object.assign(this.data, {
      id: this.data.vote_id,
      title: title?.innerHTML,
      answers,
    });
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
      alignment: {},
    };
  }

  _make(
    tagName: string,
    classNames: string[] = [],
    attributes: Record<string, any> = {}
  ) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      (el as any)[attrName] = attributes[attrName];
    }

    return el;
  }
}
