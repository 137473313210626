import classNames from "classnames";
import React, { forwardRef } from "react";

interface IInput extends React.HTMLProps<HTMLInputElement> {
  errorText?: string;
  className?: string;
  isInvalid?: boolean;
}

const Input = forwardRef<HTMLInputElement, IInput>(
  (
    { type = "text", errorText, className, id, isInvalid, ...props }: IInput,
    ref
  ) => {
    return (
      <>
        <div className="input-block">
          <input
            ref={ref}
            className={classNames([
              "input",
              isInvalid && "is-invalid",
              className,
            ])}
            type={type}
            {...props}
          />
        </div>

        {isInvalid && errorText && (
          <span className={`input-error`} style={{ display: "block" }}>
            {errorText}
          </span>
        )}
      </>
    );
  }
);

Input.displayName = "Input";

export default Input;
