import bp from './breakpoints.module.scss';

export const breakpoints = {
  desktopLarge: Number(bp.desktopLarge),
  desktopMiddle: Number(bp.desktopMiddle),
  desktopSmall: Number(bp.desktopSmall),
  desktopStart: Number(bp.desktopStart),
  tablet: Number(bp.tablet),
  tabletStart: Number(bp.tabletStart),
  mobile: Number(bp.mobile) + 1,
  mobileStart: Number(bp.mobileStart),
};
