import { getConfig } from "@/shared/lib/utils/getConfig";
import { COOKIE_AUTH_TOKEN, getCookie } from "@op/ui";
import axios from "axios";

const { REACT_APP_API_ROOT } = getConfig();

export const axiosClient = axios.create({
  baseURL: REACT_APP_API_ROOT,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getCookie(COOKIE_AUTH_TOKEN)}`;
  }
  return config;
});
