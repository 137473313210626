import { makeAutoObservable } from "mobx";

class MarkupStore {
  tagsMenuOpen = false;
  creatorModalOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleTagsMenuOpen = async (state: boolean) => {
    this.tagsMenuOpen = state;
  };

  toggleCreatorModalOpen = async (state: boolean) => {
    this.creatorModalOpen = state;
  };
}

const markupStore = new MarkupStore();
export default markupStore;
