import { preventEditorPasteText } from "@/shared/lib/utils/preventEditorPasteText";

type dataType = {
  title: string;
  prepearing: string;
  expertise: string;
  validity: string;
  sum: string;
  button: string;
  link: string;
};
export default class Service {
  api: API;
  readOnly: boolean = false;
  data: dataType;

  titlePlaceholder: string;
  prepearingPlaceholder: string;
  expertisePlaceholder: string;
  validityPlaceholder: string;
  sumPlaceholder: string;
  buttonPlaceholder: string;
  linkPlaceholder: string;

  static DEFAULT_TITLE_PLACEHOLDER: string;
  static DEFAULT_PREPEARING_PLACEHOLDER: string;
  static DEFAULT_EXPERTISE_PLACEHOLDER: string;
  static DEFAULT_VALIDITY_PLACEHOLDER: string;
  static DEFAULT_SUM_PLACEHOLDER: string;
  static DEFAULT_BUTTON_PLACEHOLDER: string;
  static DEFAULT_LINK_PLACEHOLDER: string;

  constructor({ data, config, api }: IEditor<dataType>) {
    this.api = api;

    this.titlePlaceholder =
      config.titlePlaceholder || Service.DEFAULT_TITLE_PLACEHOLDER;
    this.prepearingPlaceholder =
      config.prepearingPlaceholder || Service.DEFAULT_PREPEARING_PLACEHOLDER;
    this.expertisePlaceholder =
      config.expertisePlaceholder || Service.DEFAULT_EXPERTISE_PLACEHOLDER;
    this.validityPlaceholder =
      config.validityPlaceholder || Service.DEFAULT_VALIDITY_PLACEHOLDER;
    this.sumPlaceholder =
      config.sumPlaceholder || Service.DEFAULT_SUM_PLACEHOLDER;
    this.buttonPlaceholder =
      config.buttonPlaceholder || Service.DEFAULT_BUTTON_PLACEHOLDER;
    this.linkPlaceholder =
      config.linkPlaceholder || Service.DEFAULT_LINK_PLACEHOLDER;

    this.data = {
      title: data?.title || "",
      prepearing: data?.prepearing || "",
      expertise: data?.expertise || "",
      validity: data?.validity || "",
      sum: data?.sum || "",
      button: data?.button || "",
      link: data?.link || "",
    };
  }
  static get toolbox() {
    return {
      icon: '<svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg"><path d="M13.53 6.185l.027.025a1.109 1.109 0 0 1 0 1.568l-5.644 5.644a1.109 1.109 0 1 1-1.569-1.568l4.838-4.837L6.396 2.23A1.125 1.125 0 1 1 7.986.64l5.52 5.518.025.027zm-5.815 0l.026.025a1.109 1.109 0 0 1 0 1.568l-5.644 5.644a1.109 1.109 0 1 1-1.568-1.568l4.837-4.837L.58 2.23A1.125 1.125 0 0 1 2.171.64L7.69 6.158l.025.027z" /></svg>',
      title: "Service",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  get CSS(): Record<string, string> {
    return {
      baseClass: this.api.styles.block,
      wrapper: "cdx-service",
      title: "cdx-service__title",
      input: this.api.styles.input,
      prepearing: "cdx-service__prepearing",
      expertise: "cdx-service__expertise",
      validity: "cdx-service__validity",
      sum: "cdx-service__sum",
      button: "cdx-service__button",
      link: "cdx-service__link",
      settingsWrapper: "cdx-service-settings",
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  render() {
    const container = this._make("div", [this.CSS.baseClass, this.CSS.wrapper]);

    const title = this._make("div", [this.CSS.input, this.CSS.title], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.title,
    });

    const prepearing = this._make(
      "div",
      [this.CSS.input, this.CSS.prepearing],
      {
        contentEditable: !this.readOnly,
        innerHTML: this.data.prepearing,
      }
    );

    const expertise = this._make("div", [this.CSS.input, this.CSS.expertise], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.expertise,
    });

    const validity = this._make("div", [this.CSS.input, this.CSS.validity], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.validity,
    });

    const sum = this._make("div", [this.CSS.input, this.CSS.sum], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.sum,
    });

    const linkButton = this._make("div", [this.CSS.input, this.CSS.button], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.button,
    });

    const link = this._make("div", [this.CSS.input, this.CSS.link], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.link,
    });

    title.dataset.placeholder = this.titlePlaceholder;
    prepearing.dataset.placeholder = this.prepearingPlaceholder;
    expertise.dataset.placeholder = this.expertisePlaceholder;
    validity.dataset.placeholder = this.validityPlaceholder;
    sum.dataset.placeholder = this.sumPlaceholder;
    linkButton.dataset.placeholder = this.buttonPlaceholder;
    link.dataset.placeholder = this.linkPlaceholder;

    container.appendChild(title);
    container.appendChild(prepearing);
    container.appendChild(expertise);
    container.appendChild(validity);
    container.appendChild(sum);
    container.appendChild(linkButton);
    container.appendChild(link);

    return container;
  }

  save(serviceElement: HTMLDivElement) {
    const title = serviceElement.querySelector(`.${this.CSS.title}`);
    const prepearing = serviceElement.querySelector(`.${this.CSS.prepearing}`);
    const expertise = serviceElement.querySelector(`.${this.CSS.expertise}`);
    const validity = serviceElement.querySelector(`.${this.CSS.validity}`);
    const sum = serviceElement.querySelector(`.${this.CSS.sum}`);
    const button = serviceElement.querySelector(`.${this.CSS.button}`);
    const link = serviceElement.querySelector(`.${this.CSS.link}`);

    return Object.assign(this.data, {
      title: title?.innerHTML,
      prepearing: prepearing?.innerHTML,
      expertise: expertise?.innerHTML,
      validity: validity?.innerHTML,
      sum: sum?.innerHTML,
      button: button?.innerHTML,
      link: link?.innerHTML,
    });
  }

  static get sanitize() {
    return {
      text: {},
      alignment: {},
    };
  }

  _make(
    tagName: string,
    classNames: string[] = [],
    attributes: Record<string, any> = {}
  ) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      (el as any)[attrName] = attributes[attrName];
    }

    el.addEventListener("paste", preventEditorPasteText);

    return el;
  }
}
