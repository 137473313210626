import { toast } from "react-toastify";

import { createErrorString } from "./createErrorString";

export const notify = (text: unknown, id?: string) => {
  const error = createErrorString(text);

  toast.error(error, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...(id && { toastId: id }),
  });
};
