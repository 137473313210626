// https://github.com/editor-js/code
import Code from "@editorjs/code";
// https://github.com/editor-js/Delimiter
import Delimiter from "@editorjs/delimiter";
// https://github.com/editor-js/embed
import Embed from "@editorjs/embed";
// https://github.com/editor-js/Inline-Code
import InlineCode from "@editorjs/inline-code";
// https://github.com/editor-js/link
import LinkTool from "@editorjs/link";
// https://github.com/editor-js/list
import List from "@editorjs/list";
// https://github.com/editor-js/marker
import Marker from "@editorjs/marker";
// https://github.com/editor-js/table
import Table from "@editorjs/table";
// https://github.com/Aleksst95/header-with-anchor
import Header from "editorjs-header-with-anchor";

import { ENDPOINTS } from "../../api";
import {
  MAX_UPLOAD_IMAGE_SIZE,
  editorUploadImage,
} from "../../controllers/article-controller";
import Faq from "./tools/Faq/editor";
import Incut from "./tools/Incut/editor";
import Numeric from "./tools/Numeric/editor";
import Quote from "./tools/Quote/editor";
import Service from "./tools/Service/editor";
import Vote from "./tools/Vote/editor";
import ComplexTable from "./vendor/complexTable/dist/bundle";
// https://github.com/editor-js/image
// import ImageTool from "@editorjs/image";
import ImageTool from "./vendor/image/dist/bundle";

export const EDITOR_JS_TOOLS = (
  context: any,
  {
    uploadImageFolder,
  }: {
    uploadImageFolder: UploadImageFolderType;
  }
) => ({
  embed: Embed,

  table: Table,

  complexTable: {
    class: ComplexTable,
    inlineToolbar: false,
    config: {
      rows: 3,
      cols: 3,
    },
  },

  marker: Marker,
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  code: Code,
  paragraph: {
    config: {
      placeholder: "Нажмите Tab для выбора инструмента",
    },
  },
  header: {
    class: Header,
    config: {
      allowAnchor: true,
    },
  },
  linkTool: {
    class: LinkTool,
    config: {
      endpoint: ENDPOINTS.API_ROOT + ENDPOINTS.EDITOR.FETCH_URL,
    },
  },
  image: {
    class: ImageTool,
    inlineToolbar: true,
    config: {
      buttonContent: "Выберите изображение",
      captionPlaceholder: "Название изображения",
      urlPlaceholder: "Ссылка",
      uploader: {
        maxSize: MAX_UPLOAD_IMAGE_SIZE,
        uploadByFile: (file: File) =>
          editorUploadImage(file, uploadImageFolder),
      },
    },
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
    config: {
      textPlaceholder: "Цитата*",
      authorPlaceholder: "Автор",
      rolePlaceholder: "Должность",
      uploader: {
        maxSize: MAX_UPLOAD_IMAGE_SIZE,
        uploadByFile: (file: File) => editorUploadImage(file, "authors"),
      },
      buttonContent: "Выберите",
      tunes: [],
    },
  },
  incut: {
    class: Incut,
    inlineToolbar: true,
    config: {
      titlePlaceholder: "Заголовок Врезки",
      textPlaceholder: "Врезка*",
    },
  },
  vote: {
    class: Vote,
    inlineToolbar: true,
    config: {
      titlePlaceholder: "Название опроса*",
      answerPlaceholder: "Вариант ответа",
    },
  },
  numeric: {
    class: Numeric,
    inlineToolbar: true,
    config: {
      numeralPlaceholder: "Цифра*",
      signPlaceholder: "Подпись",
    },
  },
  faq: {
    class: Faq,
    inlineToolbar: true,
    config: {
      titlePlaceholder: "Название темы*",
      questionPlaceholder: "Вопрос",
      answerPlaceholder: "Ответ",
    },
  },
  service: {
    class: Service,
    inlineToolbar: true,
    config: {
      titlePlaceholder: "Название услуги*",
      prepearingPlaceholder: "Время подготовки (1-2 мес.)*",
      expertisePlaceholder: "Время экспертизы (10-18 мес.)*",
      validityPlaceholder: "Время срока действия (10-20 лет)*",
      sumPlaceholder: "Стоимость услуги*",
      buttonPlaceholder: "Текст в кнопке(Зарегистрировать сейчас)*",
      linkPlaceholder: "Ссылка на услугу*",
    },
  },
  delimiter: Delimiter,
  inlineCode: InlineCode,
});
