import classNames from "classnames";
import React, { forwardRef } from "react";

interface ITextarea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  errorText?: string;
  className?: string;
  isInvalid?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, ITextarea>(
  ({ errorText, className, id, isInvalid, ...props }: ITextarea, ref) => {
    return (
      <>
        <div className="textarea-block">
          <textarea
            ref={ref}
            className={classNames([
              "input",
              "textarea",
              isInvalid && "is-invalid",
              className,
            ])}
            {...props}
          />
        </div>

        {isInvalid && errorText && (
          <span className={`input-error`} style={{ display: "block" }}>
            {errorText}
          </span>
        )}
      </>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
