import { AuthAPI, AuthorAPI } from "@/api";
import { DOMAIN } from "@op/entities";
import {
  COOKIE_AUTH_TOKEN,
  LOCAL_STORAGE_USER_VOTES,
  deleteCookie,
  getCookie,
  setCookie,
} from "@op/ui";
import { makeAutoObservable } from "mobx";

import AuthorStore from "./AuthorStore";

class UserStore {
  loading: boolean = true;
  data: UserResponse | null = null;
  author: Nullable<AuthorResponse> = null;
  statistic: Nullable<AuthorStatisticsResponse> = null;
  token: string | null = null;
  isAuth: boolean | null = null;
  error: any;
  votes: Record<string, number> = {};

  constructor() {
    makeAutoObservable(this);
  }

  init = async () => {
    this.votes = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_USER_VOTES) || "{}"
    );

    const access_token = getCookie(COOKIE_AUTH_TOKEN);
    if (access_token) {
      this.token = access_token;
      await this.getUser();
      await this.getUserAuthor();
    } else {
      this.logout();
    }
    this.loading = false;
  };

  setVote = (vodeId: number, answerId: number) => {
    this.votes = {
      ...this.votes,
      [vodeId]: answerId,
    };
    localStorage.setItem(LOCAL_STORAGE_USER_VOTES, JSON.stringify(this.votes));
  };

  logout = () => {
    this.token = null;
    this.data = null;
    this.isAuth = false;
    this.author = null;
    this.statistic = null;
    deleteCookie(COOKIE_AUTH_TOKEN);
  };

  getUser = async () => {
    if (this.token) {
      const response = await AuthAPI.getUser(this.token);
      if (response) {
        this.data = response;
        this.isAuth = true;
      } else {
        this.logout();
      }
    }
  };

  getUserAuthor = async () => {
    if (this.data) {
      this.author =
        AuthorStore.data.find(
          (author) => author.user_id === this.data?.user_id
        ) || null;
      if (this.author) {
        this.statistic = await AuthorAPI.getAuthorStatistics(this.data.user_id);
      }
    }
  };

  login = async (data: LoginRequest) => {
    try {
      const response = await AuthAPI.getToken(data);
      if (response) {
        this.token = response.access_token;
        setCookie(COOKIE_AUTH_TOKEN, this.token, {
          domain: DOMAIN,
        });
      }
      await this.getUser();
      this.getUserAuthor();
    } catch (error) {
      console.info(error);
      return null;
    }
  };
}

const userStore = new UserStore();
export default userStore;
