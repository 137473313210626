export const pagination = (
  currentPosition: number,
  pageSize: number,
  itemsLength: number,
  forward: boolean
) => {
  const nextPosition = currentPosition + (forward ? 1 : -1) * pageSize;
  if (nextPosition >= 0 && nextPosition <= itemsLength) {
    return nextPosition;
  } else {
    return currentPosition;
  }
};
