import { OpTheme } from '../../../providers';
import { transformPxToVwInStyleObject } from '../../../utils';

export const SvgIconVarsMedias = () => {
  const vars = {
    '--OP-svgIcon-size-width': OpTheme.spacing(6),
    '--OP-svgIcon-size-height': OpTheme.spacing(6),
    '--OP-svgIcon-size-small': OpTheme.spacing(4),
    '--OP-svgIcon-size-medium': OpTheme.spacing(6),
    '--OP-svgIcon-size-large': OpTheme.spacing(8),
  };

  const varsSmall = transformPxToVwInStyleObject(vars, OpTheme.breakpoints.values.desktopSmall);
  const varsLarge = transformPxToVwInStyleObject(vars, OpTheme.breakpoints.values.desktopLarge);

  return {
    ...vars,
    [OpTheme.breakpoints.between('tabletStart', 'desktopSmall')]: varsSmall,
    [OpTheme.breakpoints.up('desktopLarge')]: varsLarge,
  };
};
