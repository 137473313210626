import { API_PROMO_URL } from "@op/entities";
import { fileToBinary } from "@op/ui";
import axios from "axios";

import UserStore from "../app/providers/MobxStore/model/UserStore";
import { sendRequest } from "../shared/lib/utils/sendRequest";
import { ENDPOINTS } from "./endpoints";

const baseUrl = ENDPOINTS.API_ROOT;

export const getAllArticles = async (): Promise<
  Nullable<ArticleResponse[]>
> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.PATH);
};

export interface IGetArticlesByFilterRequest {
  filter?: TFilterParams;
  start?: number;
  total?: number;
  sort_field?: string; // likes/date/view_counts
  sort_asc?: boolean;
}

export const getArticlesByFilter = ({
  filter,
  start,
  total,
  sort_asc,
  sort_field,
}: IGetArticlesByFilterRequest): Promise<Nullable<ArticleFilterResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.FILTER.PATH, {
    body: { start, total, filter, sort_asc, sort_field },
  });
};

export const getPartialArticles = async (
  start: number,
  count: number
): Promise<Nullable<ArticleResponse[]>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.PATH, {
    body: { start, count },
  });
};

export const getArticleDetailByIndex = async (
  index: string
): Promise<Nullable<ArticleDetailResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.DETAILS.PATH + index);
};

export const getArticlesByTagIndex = async (
  index: string
): Promise<Nullable<ArticleResponse[]>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.FILTER.BY_TAG_INDEX + index);
};

export const getLatestNews = async (): Promise<Nullable<ArticleResponse[]>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.FILTER.LATEST_NEWS);
};

export const getImportantArticles = async (): Promise<
  Nullable<ArticleResponse[]>
> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.FILTER.IMPORTANT);
};

export const getMainArticles = async (): Promise<
  Nullable<ArticleResponse[]>
> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.FILTER.MAIN);
};

export const addArticle = async (
  article: Partial<ArticleDetail>
): Promise<Nullable<{ id: number }>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.ADD, {
    body: article,
  });
};

export const editArticle = async (
  article: ArticleDetail
): Promise<Nullable<string>> => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.EDIT, {
    body: article,
  });
};

export const deleteArticle = async (article_id: number) => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.DELETE, {
    body: { id: article_id },
  });
};

export const likeArticle = async (article_id: number) => {
  return sendRequest(baseUrl + ENDPOINTS.ARTICLE.DETAILS.ADD_LIKE + article_id);
};

export const increaseViewCountArticle = async (article_id: number) => {
  return sendRequest(
    baseUrl + ENDPOINTS.ARTICLE.DETAILS.VIEW_COUNT + article_id
  );
};

export const uploadImage = async (
  file: File,
  folder: UploadImageFolderType
) => {
  const binaryData = await fileToBinary(file);
  return axios.post<ImageUploadResponse>(
    `${API_PROMO_URL}/images`,
    binaryData,
    {
      headers: {
        Authorization: `Bearer ${UserStore.token}`,
        "Content-Type": file.type,
        "X-FileName": file.name,
        "X-UserName": "admin",
        "X-FolderName": folder,
      },
    }
  );
};

export const addVoteBlock = (
  data: VoteResponse
): Promise<Nullable<{ vote_id: number }>> => {
  return sendRequest(baseUrl + ENDPOINTS.VOTES.ADD, { body: data });
};

export const getVoteBlock = (
  vote_id: number
): Promise<Nullable<VoteResponse>> => {
  return sendRequest(baseUrl + ENDPOINTS.VOTES.GET + vote_id);
};

export const editVoteBlock = (
  data: VoteResponse
): Promise<Nullable<{ vote_id: number }>> => {
  return sendRequest(baseUrl + ENDPOINTS.VOTES.EDIT, { body: data });
};

export const addVoteAnswer = (
  vote_id: VoteResponse["vote_id"],
  answer_id: VoteResponse["answers"][0]["answer_id"]
) => {
  return sendRequest(
    baseUrl + ENDPOINTS.VOTES.ADD_COUNT + `${vote_id}_${answer_id}`
  );
};
