'use client';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export const ListTheme = extendTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  }
})