import { axiosClient } from "../../../api/axiosClient";
import i18n from "../../../i18n";
import { notify } from "./notify";

interface IOptionsInterface {
  method?: "POST" | "GET" | "PUT" | "DELETE";
  headers?: Record<string, any>;
  body?: any;
  errorMessage?: string;
}

export const sendRequest = async <Res>(
  requestUrl: string,
  options?: IOptionsInterface
): Promise<Res | null> => {
  try {
    const res = await axiosClient({
      method: options?.method || "POST",
      url: requestUrl,
      headers: options?.headers,
      data: options?.body || {},
    });

    const { payload, response, message } = await res.data;

    if (response === "success" && res.status === 200) {
      return payload || {};
    } else {
      throw message;
    }
  } catch (message) {
    notify(options?.errorMessage || i18n.t("AnErrorOcurred"), "sendRequest");

    console.log(`sendRequest - ${requestUrl} error: ${message}`);
    return null;
  }
};
