import useOnClickOutside from "@/shared/lib/hooks/useOnClickOutside";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ISelect<L, V> {
  value?: V;
  setValue: (value: V | undefined) => void;
  items?: { value: V; label: L; isDefault?: boolean }[];
  searchPlaceholder?: string;
  buttonPlaceholder?: L;
  button: (label?: L) => React.ReactElement;
  option?: (label: L) => React.ReactElement;
  searchFunction?: ({ label, search }: { label: L; search: string }) => boolean;
  className?: string;
  isInvalid?: boolean;
  dropDownClassName?: string;
}

const Select = <L extends unknown, V extends string | number>({
  className,
  dropDownClassName,
  searchPlaceholder,
  items,
  value,
  setValue,
  buttonPlaceholder,
  button,
  option,
  isInvalid,
  searchFunction,
}: ISelect<L, V>) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(buttonRef, (event) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  });

  let filteredItems = items || [];
  if (searchFunction) {
    filteredItems = filteredItems.filter(({ label }) =>
      searchFunction({ label, search: searchValue.toLowerCase() })
    );
  }

  return (
    <label
      className={classNames(
        "select",
        className,
        isOpen && "is-open",
        isInvalid && "is-invalid"
      )}
    >
      <div ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
        {button(
          items?.find((item) => item.value === value)?.label ||
            buttonPlaceholder
        )}
      </div>

      {option && (
        <div className={`dropdown ${dropDownClassName}`} ref={buttonRef}>
          {searchFunction && (
            <div className="dropdown__search">
              <input
                className="dropdown__search-input"
                type="text"
                value={searchValue}
                placeholder={searchPlaceholder}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </div>
          )}
          <ul className="dropdown__list">
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <li
                  className={classNames("dropdown__item select__item", {
                    "is-active":
                      item.value === value || (!value && item.isDefault),
                  })}
                  key={index}
                >
                  <button
                    className="dropdown__btn"
                    onClick={() => {
                      setValue(item.value);
                      setIsOpen(false);
                    }}
                  >
                    {option(item.label)}
                  </button>
                </li>
              ))
            ) : (
              <li className="dropdown__item select__item select__item-none">
                <span className="dropdown__btn  select__text-none">
                  {t("NothingFound")}
                </span>
              </li>
            )}
          </ul>
        </div>
      )}

      {!option && (
        <select
          className="dropdown__select"
          onChange={(event) => setValue(event.target.value as typeof value)}
        >
          {filteredItems.map((item) => (
            <option value={item.value} key={item.value}>
              {typeof item.label === "string"
                ? item.label
                : JSON.stringify(item.label)}
            </option>
          ))}
        </select>
      )}
    </label>
  );
};

export default Select;
