import { rtkApi } from '@op/api';

import { API_PROMO_URL } from '../../Env';
import { ITrademarksRegisteredByUsRequest, ITrademarksRegisteredByUsResponse } from '../model/types/trademark';

const trademarkApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getTrademarksRegisteredByUs: build.query<ITrademarksRegisteredByUsResponse, ITrademarksRegisteredByUsRequest>({
      query: ({ mktuClass }) => ({
        url: `${API_PROMO_URL}/trademarks/mktu-class/${mktuClass}`,
        headers: {
          'X-API-KEY': '9397A169-6111-4C2D-B1DB-078E3869598D',
        },
      }),
    }),
  }),
});

export const useGetTrademarksRegisteredByUs = trademarkApi.useGetTrademarksRegisteredByUsQuery;
export const useLazyGetTrademarksRegisteredByUs = trademarkApi.useLazyGetTrademarksRegisteredByUsQuery;
