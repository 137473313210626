import React from "react";

interface IRadio
  extends Omit<React.HTMLProps<HTMLInputElement>, "value" | "onChange"> {
  errorText?: string;
  className?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

const Radio = ({
  className,
  errorText,
  label,
  value,
  onChange,
  ...props
}: IRadio) => {
  return (
    <label className="radio">
      {value ? (
        <span className="radio__switch is-on" />
      ) : (
        <span className="radio__switch" />
      )}

      <input
        className={`radio__input`}
        type="checkbox"
        value={value.toString()}
        onChange={() => onChange(!value)}
        {...props}
      />

      <span className="radio__text">{label}</span>

      {errorText && errorText !== "" && <span className={`input__error`} />}
    </label>
  );
};

export default Radio;
