import { DATE_FORMAT_UTC } from "@/shared/consts/common";
import ru from "date-fns/locale/ru";
import moment from "moment";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ru", ru);
interface IDatePicker {
  value?: DateStringUTC;
  onChange: (value: string) => void;
}

const DatePicker = ({ onChange, value }: IDatePicker) => {
  const handleChange: ReactDatePickerProps["onChange"] = (date) => {
    if (date) {
      const dateString = moment(date).startOf("day").format(DATE_FORMAT_UTC);
      onChange(dateString);
    }
  };
  return (
    <div className="datepicker">
      <ReactDatePicker
        selected={moment(value).toDate()}
        onChange={handleChange}
        dateFormat="dd.MM.yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale="ru"
      />
    </div>
  );
};

export default DatePicker;
