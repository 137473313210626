export const ROUTES = {
  HOME: { label: "HomePage", path: "/" },
  GENERAL: {
    AUTHORS: { label: "AuthorsList", path: "/authors" },
  },
  JOURNAL: {
    ARTICLES: { label: "Articles", path: "/journal/articles" },
    TAGS: { label: "Tags", path: "/journal/tags" },
  },
  FAQ: {
    ARTICLES: { label: "Articles", path: "/faq/articles" },
    TAGS: { label: "Tags", path: "/faq/tags" },
  },
};
