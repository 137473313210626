import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_URL } from '../../Env';
import {
  IFaqArticleAddRequest,
  IFaqArticleDeleteRequest,
  IFaqArticleDetailGetRequest,
  IFaqArticleDetailGetResponse,
  IFaqArticleEditRequest,
  IFaqArticleLikeRequest,
  IFaqArticleListGetRequest,
  IFaqArticleListGetResponse,
  IFaqArticleViewRequest,
} from '../model/types/faqArticle';

const ENDPOINT = `${API_PROMO_URL}/faq/articles/`;

const requestListOptions = ({ token, ...params }: IFaqArticleListGetRequest) => ({
  url: ENDPOINT,
  params,
  ...(token && {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
});

const requestDetailOptions = ({ index, ...params }: IFaqArticleDetailGetRequest) => ({
  url: `${ENDPOINT}${index ?? ''}`,
  params,
});

const requestMutateOptions = ({ token, ...params }: IFaqArticleEditRequest | IFaqArticleAddRequest | IFaqArticleDeleteRequest) => ({
  url: `${ENDPOINT}${params.id ?? ''}`,
  params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const requestLikeOptions = ({ ...params }: IFaqArticleLikeRequest) => ({
  url: `${ENDPOINT}like/${params.id ?? ''}`,
});

const requestViewOptions = ({ ...params }: IFaqArticleViewRequest) => ({
  url: `${ENDPOINT}view-counts/${params.id ?? ''}`,
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getFaqArticleList: build.query<IFaqArticleListGetResponse, IFaqArticleListGetRequest>({
      query: requestListOptions,
    }),
    getFaqArticleDetail: build.query<IFaqArticleDetailGetResponse, IFaqArticleDetailGetRequest>({
      query: requestDetailOptions,
    }),
    addFaqArticle: build.mutation<number, IFaqArticleAddRequest>({
      query: (params) => ({
        url: requestMutateOptions(params).url,
        method: 'post',
        data: params,
      }),
    }),
    editFaqArticle: build.mutation<number, IFaqArticleEditRequest>({
      query: (params) => ({
        url: requestMutateOptions(params).url,
        method: 'put',
        data: params,
      }),
    }),
    deleteFaqArticle: build.mutation<number, IFaqArticleDeleteRequest>({
      query: (params) => ({
        url: requestMutateOptions(params).url,
        method: 'delete',
      }),
    }),
    likeFaqArticle: build.mutation<number, IFaqArticleLikeRequest>({
      query: (params) => ({
        url: requestLikeOptions(params).url,
        method: 'post',
      }),
    }),
    viewFaqArticle: build.mutation<number, IFaqArticleLikeRequest>({
      query: (params) => ({
        url: requestViewOptions(params).url,
        method: 'post',
      }),
    }),
  }),
});

export const {
  useGetFaqArticleListQuery: useGetFaqArticleList,
  useLazyGetFaqArticleListQuery: useLazyGetFaqArticleList,
  useGetFaqArticleDetailQuery: useGetFaqArticleDetail,
  useLazyGetFaqArticleDetailQuery: useLazyGetFaqArticleDetail,
  useAddFaqArticleMutation,
  useEditFaqArticleMutation,
  useDeleteFaqArticleMutation,
  useLikeFaqArticleMutation,
  useViewFaqArticleMutation,
} = api;

export const getFaqArticleList = (params: IFaqArticleListGetRequest): Promise<AxiosResponse<IFaqArticleListGetResponse>> =>
  $api.request(requestListOptions(params));

export const getFaqArticleDetail = (params: IFaqArticleDetailGetRequest): Promise<AxiosResponse<IFaqArticleDetailGetResponse>> =>
  $api.request(requestDetailOptions(params));

export const addFaqArticle = (params: IFaqArticleAddRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, params: data } = requestMutateOptions(params);
  return $api.request({ url, headers, method: 'post', data });
};

export const editFaqArticle = (params: IFaqArticleEditRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, params: data } = requestMutateOptions(params);
  return $api.request({ url, headers, method: 'put', data });
};

export const deleteFaqArticle = (params: IFaqArticleDeleteRequest): Promise<AxiosResponse<number>> => {
  const { url, headers } = requestMutateOptions(params);
  return $api.request({ url, headers, method: 'delete' });
};

export const likeFaqArticle = (params: IFaqArticleLikeRequest): Promise<AxiosResponse<number>> => {
  const { url } = requestLikeOptions(params);
  return $api.request({ url, method: 'post' });
};

export const viewFaqArticle = (params: IFaqArticleViewRequest): Promise<AxiosResponse<number>> => {
  const { url } = requestViewOptions(params);
  return $api.request({ url, method: 'post' });
};
