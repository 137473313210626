import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AuthorStore from "../../app/providers/MobxStore/model/AuthorStore";
import MarkupStore from "../../app/providers/MobxStore/model/MarkupStore";
import TagStore from "../../app/providers/MobxStore/model/TagStore";
import UserStore from "../../app/providers/MobxStore/model/UserStore";
import { ROUTES } from "../../app/providers/Router/model/consts/routerConsts";
import FaqArticlesModalEditor from "../../widgets/FaqArticlesModalEditor/ui/FaqArticlesModalEditor";
import FaqTagsModal from "../../widgets/FaqTagsModal/ui/FaqTagsModal";
import AuthorModal from "../Authors/AuthorModal";
import JournalArticlesModalEditor from "../JournalArticles/JournalArticlesModalEditor";
import JournalTagsModal from "../JournalTags/JournalTagsModal";
import { ProfileAsideGroup } from "./ProfileAsideGroup";

const ProfileAside = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    UserStore.logout();
    navigate(ROUTES.HOME.path);
  };
  const {
    creatorModalOpen: journalArticlesCreatorModalOpen,
    toggleCreatorModalOpen,
  } = MarkupStore;
  const [authorCreatorOpened, setAuthorCreatorOpened] = useState(false);
  const [journalTagCreatorOpened, setJournalTagCreatorOpened] = useState(false);
  const [faqTagsCreatorOpened, setFaqTagsCreatorOpened] = useState(false);
  const [faqArticlesCreatorOpened, setFaqArticlesCreatorOpened] =
    useState(false);
  const { t } = useTranslation();

  const handleJouralArticleCreatorModalOnSave = () => {
    navigate(ROUTES.HOME.path);
  };

  const handleJournalTagCreatorOnSave = () => {
    TagStore.fetchTags();
    navigate(ROUTES.JOURNAL.TAGS.path);
  };

  const handleAuthorCreatorOnSave = () => {
    AuthorStore.fetchAuthors();
    navigate(ROUTES.GENERAL.AUTHORS.path);
  };

  const handleFaqTagCreatorOnSave = () => {
    navigate(ROUTES.FAQ.TAGS.path);
  };

  const handleFaqArticleCreatorOnSave = () => {
    navigate(ROUTES.FAQ.ARTICLES.path);
  };

  const generalMenuItems = useMemo(
    () => [
      {
        name: t("AuthorsList"),
        link: ROUTES.GENERAL.AUTHORS.path,
        onPlusClick: () => setAuthorCreatorOpened(true),
      },
    ],
    [t]
  );

  const journalMenuItems = useMemo(
    () => [
      {
        name: t("Articles"),
        link: ROUTES.HOME.path,
        onPlusClick: () => MarkupStore.toggleCreatorModalOpen(true),
      },
      {
        name: t("Tags"),
        link: ROUTES.JOURNAL.TAGS.path,
        onPlusClick: () => setJournalTagCreatorOpened(true),
      },
    ],
    [t]
  );

  const faqMenuItems = useMemo(
    () => [
      {
        name: t("Articles"),
        link: ROUTES.FAQ.ARTICLES.path,
        onPlusClick: () => setFaqArticlesCreatorOpened(true),
      },
      {
        name: t("Tags"),
        link: ROUTES.FAQ.TAGS.path,
        onPlusClick: () => setFaqTagsCreatorOpened(true),
      },
    ],
    [t]
  );

  return (
    <>
      <div className="profile-page__aside aside">
        <ProfileAsideGroup title={t("General")} items={generalMenuItems} />
        <ProfileAsideGroup title={t("Journal")} items={journalMenuItems} />
        <ProfileAsideGroup title={t("Faq")} items={faqMenuItems} />

        <div className="profile-page__aside-menu-bottom">
          <ul className="aside-menu article-page__aside-menu">
            <li className="aside-menu__item">
              <button
                onClick={handleLogout}
                className="aside-menu__link aside-menu__link_static"
              >
                <span className="aside-menu__item-name">{t("Exit")}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <JournalArticlesModalEditor
        open={journalArticlesCreatorModalOpen}
        onClose={() => toggleCreatorModalOpen(false)}
        data={null}
        onSave={handleJouralArticleCreatorModalOnSave}
      />
      <JournalTagsModal
        open={journalTagCreatorOpened}
        onClose={() => setJournalTagCreatorOpened(false)}
        data={null}
        onSave={handleJournalTagCreatorOnSave}
      />
      <AuthorModal
        open={authorCreatorOpened}
        onClose={() => setAuthorCreatorOpened(false)}
        data={null}
        onSave={handleAuthorCreatorOnSave}
      />
      <FaqArticlesModalEditor
        open={faqArticlesCreatorOpened}
        onClose={() => setFaqArticlesCreatorOpened(false)}
        onSave={handleFaqArticleCreatorOnSave}
      />
      <FaqTagsModal
        open={faqTagsCreatorOpened}
        onClose={() => setFaqTagsCreatorOpened(false)}
        onSave={handleFaqTagCreatorOnSave}
      />
    </>
  );
};

export default observer(ProfileAside);
