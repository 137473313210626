import colorsConsts from './colors.module.scss';

export const colors = {
  base: {
    black: colorsConsts.base_black,
    white: colorsConsts.base_white,
    blue: colorsConsts.base_blue,
    lightBlue: colorsConsts.base_lightBlue,
    red: colorsConsts.base_red,
    green: colorsConsts.base_green,
    orange: colorsConsts.base_orange,
    bgBlack: colorsConsts.base_bgBlack,
    darkGray: colorsConsts.base_darkGray,
    lightGray: colorsConsts.base_lightGray,
    gray1: colorsConsts.base_gray1,
    gray1LightTheme: colorsConsts.base_gray1LightTheme,
  },
  toxic: {
    c_0: colorsConsts.toxicC_0,
    c_50: colorsConsts.toxicC_50,
    c_100: colorsConsts.toxicC_100,
    c_200: colorsConsts.toxicC_200,
    c_300: colorsConsts.toxicC_300,
    c_500: colorsConsts.toxicC_500,
    c_600: colorsConsts.toxicC_600,
    c_700: colorsConsts.toxicC_700,
    c_800: colorsConsts.toxicC_800,
    c_900: colorsConsts.toxicC_900,
  },
  gray: {
    c_0: colorsConsts.grayC_0,
    c_50: colorsConsts.grayC_50,
    c_100: colorsConsts.grayC_100,
    c_200: colorsConsts.grayC_200,
    c_300: colorsConsts.grayC_300,
    c_400: colorsConsts.grayC_400,
    c_500: colorsConsts.grayC_500,
    c_600: colorsConsts.grayC_600,
    c_800: colorsConsts.grayC_800,
    c_900: colorsConsts.grayC_900,
    c_1000: colorsConsts.grayC_1000,
    c_1100: colorsConsts.grayC_1100,
  },
  avocado: {
    c_0: colorsConsts.avocadoC_0,
    c_50: colorsConsts.avocadoC_50,
    c_100: colorsConsts.avocadoC_100,
    c_200: colorsConsts.avocadoC_200,
    c_300: colorsConsts.avocadoC_300,
    c_400: colorsConsts.avocadoC_400,
    c_500: colorsConsts.avocadoC_500,
    c_600: colorsConsts.avocadoC_600,
    c_800: colorsConsts.avocadoC_800,
    c_900: colorsConsts.avocadoC_900,
  },
  green: {
    c_0: colorsConsts.greenC_0,
    c_50: colorsConsts.greenC_50,
    c_100: colorsConsts.greenC_100,
    c_200: colorsConsts.greenC_200,
    c_300: colorsConsts.greenC_300,
    c_500: colorsConsts.greenC_500,
    c_600: colorsConsts.greenC_600,
    c_700: colorsConsts.greenC_700,
    c_800: colorsConsts.greenC_800,
    c_900: colorsConsts.greenC_900,
  },
  gray_steel: {
    c_0: colorsConsts.gray_steelC_0,
    c_50: colorsConsts.gray_steelC_50,
    c_200: colorsConsts.gray_steelC_200,
    c_300: colorsConsts.gray_steelC_300,
    c_400: colorsConsts.gray_steelC_400,
    c_500: colorsConsts.gray_steelC_500,
    c_600: colorsConsts.gray_steelC_600,
    c_700: colorsConsts.gray_steelC_700,
    c_800: colorsConsts.gray_steelC_800,
    c_900: colorsConsts.gray_steelC_900,
  },
  white: {
    t_08: colorsConsts.whiteT_08,
    t_12: colorsConsts.whiteT_12,
    t_16: colorsConsts.whiteT_16,
    t_24: colorsConsts.whiteT_24,
    t_32: colorsConsts.whiteT_32,
    t_40: colorsConsts.whiteT_40,
    t_50: colorsConsts.whiteT_50,
    t_64: colorsConsts.whiteT_64,
    t_72: colorsConsts.whiteT_72,
    t_80: colorsConsts.whiteT_80,
    t_88: colorsConsts.whiteT_88,
    t_100: colorsConsts.whiteT_100,
  },
  black: {
    t_08: colorsConsts.blackT_08,
    t_12: colorsConsts.blackT_12,
    t_16: colorsConsts.blackT_16,
    t_24: colorsConsts.blackT_24,
    t_32: colorsConsts.blackT_32,
    t_40: colorsConsts.blackT_40,
    t_50: colorsConsts.blackT_50,
    t_64: colorsConsts.blackT_64,
    t_72: colorsConsts.blackT_72,
    t_80: colorsConsts.blackT_80,
    t_88: colorsConsts.blackT_88,
    t_100: colorsConsts.blackT_100,
  },
  blue: {
    c_0: colorsConsts.blueC_0,
    c_50: colorsConsts.blueC_50,
    c_100: colorsConsts.blueC_100,
    c_200: colorsConsts.blueC_200,
    c_300: colorsConsts.blueC_300,
    c_500: colorsConsts.blueC_500,
    c_600: colorsConsts.blueC_600,
    c_700: colorsConsts.blueC_700,
    c_800: colorsConsts.blueC_800,
    c_900: colorsConsts.blueC_900,
  },
  red: {
    c_0: colorsConsts.redC_0,
    c_50: colorsConsts.redC_50,
    c_100: colorsConsts.redC_100,
    c_200: colorsConsts.redC_200,
    c_300: colorsConsts.redC_300,
    c_500: colorsConsts.redC_500,
    c_600: colorsConsts.redC_600,
    c_700: colorsConsts.redC_700,
    c_800: colorsConsts.redC_800,
    c_900: colorsConsts.redC_900,
  },
  orange: {
    c_0: colorsConsts.orangeC_0,
    c_50: colorsConsts.orangeC_50,
    c_100: colorsConsts.orangeC_100,
    c_200: colorsConsts.orangeC_200,
    c_300: colorsConsts.orangeC_300,
    c_500: colorsConsts.orangeC_500,
    c_600: colorsConsts.orangeC_600,
    c_700: colorsConsts.orangeC_700,
    c_800: colorsConsts.orangeC_800,
    c_900: colorsConsts.orangeC_900,
  },
  yellow: {
    c_0: colorsConsts.yellowC_0,
    c_50: colorsConsts.yellowC_50,
    c_100: colorsConsts.yellowC_100,
    c_200: colorsConsts.yellowC_200,
    c_300: colorsConsts.yellowC_300,
    c_500: colorsConsts.yellowC_500,
    c_600: colorsConsts.yellowC_600,
    c_700: colorsConsts.yellowC_700,
    c_800: colorsConsts.yellowC_800,
    c_900: colorsConsts.yellowC_900,
  },
};
