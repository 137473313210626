import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { CSSTransition } from "react-transition-group";

type IModal = IModalOpen & {
  children: ReactElement;
  canBeExpanded?: boolean;
  className?: string;
};

export function Modal({
  children,
  open,
  onClose,
  canBeExpanded = false,
  className,
  ...props
}: IModal) {
  const [expand, setExpand] = useState(false);

  const childrenWithProps = React.cloneElement(children, {
    ...props,
  });

  const triggerFullScreen = () => {
    if (!expand) {
      setExpand(true);
      document.body.requestFullscreen();
    } else {
      setExpand(false);
      document.exitFullscreen();
    }
  };

  const handleClose = () => {
    if (expand) {
      triggerFullScreen();
    }
    onClose();
  };

  useEffect(() => {
    const handleExitFullScreen = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setExpand(false);
      }
    };

    if (document.addEventListener) {
      document.addEventListener("fullscreenchange", handleExitFullScreen);
    }
    return () => {
      document.removeEventListener("fullscreenchange", handleExitFullScreen);
    };
  }, []);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [onClose]);

  return (
    <CSSTransition
      in={open}
      timeout={300}
      classNames="Modal-transition"
      unmountOnExit
    >
      <div
        className={classNames([
          "Modal",
          expand ? "Modal--expand" : "",
          className,
        ])}
      >
        <div className="Modal-cover" onClick={onClose} />
        <div className="Modal-body">
          {canBeExpanded && (
            <button
              className="btn-icon Modal-resize-btn"
              onClick={triggerFullScreen}
            >
              <span
                className={`icon icon-resize-${expand ? "expand" : "shrink"}`}
              />
            </button>
          )}
          <button className="btn-icon Modal-close-btn" onClick={handleClose}>
            <span className="icon icon-close" />
          </button>

          {childrenWithProps}
        </div>
      </div>
    </CSSTransition>
  );
}
