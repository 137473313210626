import { observer } from "mobx-react-lite";
import React from "react";

import UserStore from "../app/providers/MobxStore/model/UserStore";
import Author from "./Authors/Author";

const ProfileHeader = () => {
  const { data, author } = UserStore;

  return (
    <div className="profile-header">
      <Author
        name={author?.name || data?.full_name}
        initials={author?.initials || data?.username.slice(0, 2)}
        avatar={author?.avatar}
      />
    </div>
  );
};

export default observer(ProfileHeader);
