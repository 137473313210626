import classNames from "classnames";
import React from "react";

interface IPaginationArrows {
  onClick: (next?: boolean) => void;
  currentPosition: number;
  paginationSize: number;
  catalogLength: number;
}

const PaginationArrows = (props: IPaginationArrows) => {
  const { currentPosition, paginationSize, catalogLength, onClick } = props;
  return (
    <div className="profile__pagination">
      <button
        onClick={() => onClick()}
        className={classNames(
          "btn-icon",
          currentPosition === 0 && "is-disabled"
        )}
      >
        <span className="icon icon-arrow-long-left" />
      </button>
      <button
        onClick={() => onClick(true)}
        className={classNames(
          "btn-icon",
          currentPosition + paginationSize >= catalogLength && "is-disabled"
        )}
      >
        <span className="icon icon-arrow-long" />
      </button>
    </div>
  );
};

export default PaginationArrows;
