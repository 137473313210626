import { transformPxToVwInStyleObject } from '../../utils';
import { OpTheme } from '../../providers';
import { buttonScheme } from './ButtonScheme';

export const buttonVarsMedias = () => {
  const vars = {
    '--OP-Xl-buttons-minHeight': buttonScheme.Xl.minHeight,
    '--OP-Xl-buttons-padding': buttonScheme.Xl.padding,
    '--OP-Xl-buttons-fontSize': buttonScheme.Xl.fontSize,
    '--OP-Xl-buttons-borderRadius': buttonScheme.Xl.borderRadius,
    '--OP-Xl-buttons-iconSize': buttonScheme.Xl.iconSize,
    '--OP-Xl-buttons-iconMargin': buttonScheme.Xl.iconMargin,
    '--OP-L-buttons-minHeight': buttonScheme.L.minHeight,
    '--OP-L-buttons-padding': buttonScheme.L.padding,
    '--OP-L-buttons-fontSize': buttonScheme.L.fontSize,
    '--OP-L-buttons-borderRadius': buttonScheme.L.borderRadius,
    '--OP-L-buttons-iconSize': buttonScheme.L.iconSize,
    '--OP-L-buttons-iconMargin': buttonScheme.L.iconMargin,
    '--OP-M-buttons-minHeight': buttonScheme.M.minHeight,
    '--OP-M-buttons-padding': buttonScheme.M.padding,
    '--OP-M-buttons-fontSize': buttonScheme.M.fontSize,
    '--OP-M-buttons-borderRadius': buttonScheme.M.borderRadius,
    '--OP-M-buttons-iconSize': buttonScheme.M.iconSize,
    '--OP-M-buttons-iconMargin': buttonScheme.M.iconMargin,
    '--OP-S-buttons-minHeight': buttonScheme.S.minHeight,
    '--OP-S-buttons-padding': buttonScheme.S.padding,
    '--OP-S-buttons-fontSize': buttonScheme.S.fontSize,
    '--OP-S-buttons-borderRadius': buttonScheme.S.borderRadius,
    '--OP-S-buttons-iconSize': buttonScheme.S.iconSize,
    '--OP-S-buttons-iconMargin': buttonScheme.S.iconMargin,
    '--OP-Xs-buttons-minHeight': buttonScheme.Xs.minHeight,
    '--OP-Xs-buttons-padding': buttonScheme.Xs.padding,
    '--OP-Xs-buttons-fontSize': buttonScheme.Xs.fontSize,
    '--OP-Xs-buttons-borderRadius': buttonScheme.Xs.borderRadius,
    '--OP-Xs-buttons-iconSize': buttonScheme.Xs.iconSize,
    '--OP-Xs-buttons-iconMargin': buttonScheme.Xs.iconMargin,
    '--OP-Xxs-buttons-minHeight': buttonScheme.Xxs.minHeight,
    '--OP-Xxs-buttons-padding': buttonScheme.Xxs.padding,
    '--OP-Xxs-buttons-fontSize': buttonScheme.Xxs.fontSize,
    '--OP-Xxs-buttons-borderRadius': buttonScheme.Xxs.borderRadius,
    '--OP-Xxs-buttons-iconSize': buttonScheme.Xxs.iconSize,
    '--OP-Xxs-buttons-iconMargin': buttonScheme.Xxs.iconMargin,
  };

  const varsSmall = transformPxToVwInStyleObject(vars, OpTheme.breakpoints.values.desktopSmall);

  const varsLarge = transformPxToVwInStyleObject(vars, OpTheme.breakpoints.values.desktopLarge);

  return {
    ...vars,
    '--OP-Xl-buttons-lineHeight': buttonScheme.Xl.lineHeight,
    '--OP-L-buttons-lineHeight': buttonScheme.L.lineHeight,
    '--OP-M-buttons-lineHeight': buttonScheme.M.lineHeight,
    '--OP-S-buttons-lineHeight': buttonScheme.S.lineHeight,
    '--OP-Xs-buttons-lineHeight': buttonScheme.Xs.lineHeight,
    '--OP-Xxs-buttons-lineHeight': buttonScheme.Xxs.lineHeight,
    [OpTheme.breakpoints.between('tabletStart', 'desktopSmall')]: varsSmall,
    [OpTheme.breakpoints.up('desktopLarge')]: varsLarge,
  };
};
