import { Outlet } from "react-router-dom";

import ProfileAside from "./ProfileAside/ProfileAside";
import ProfileHeader from "./ProfileHeader";

export default function ProfileContainer() {
  return (
    <div className="profile-section">
      <div className="wrap">
        <div className="profile profile-page">
          <ProfileHeader />

          <div className="profile-page__columns">
            <ProfileAside />
            <div className="profile-page__center">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
