import { Error404 } from "@/pages/Error";
import React from "react";

interface IPrivateProps {
  Component: React.FC<any>;
  allowed: boolean;
}

export function Private({ Component, allowed }: IPrivateProps) {
  return allowed ? <Component /> : <Error404 />;
}
