import classNames from "classnames";
import React, { ReactElement } from "react";

interface ICheckBoxLabel {
  labelElement?: ReactElement | string;
}

interface ICheckBox
  extends Omit<
    ICheckBoxLabel & React.HTMLProps<HTMLInputElement>,
    "value" | "onChange"
  > {
  errorText?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

const Checkbox = ({
  errorText,
  label,
  labelElement,
  value,
  onChange,
  ...props
}: ICheckBox) => {
  return (
    <>
      <label className={classNames(["checkbox", props.className])}>
        {value ? (
          <span className="checkbox__icon is-checked" />
        ) : (
          <span className="checkbox__icon" />
        )}

        <input
          className={`checkbox__input`}
          type="checkbox"
          value={value.toString()}
          onChange={() => onChange(!value)}
          {...props}
        />

        <span className="checkbox__text">
          {labelElement ? labelElement : label}
        </span>
      </label>
      {errorText && errorText !== "" && (
        <span className={`input-error`} style={{ display: "block" }}>
          {errorText}
        </span>
      )}
    </>
  );
};

export default Checkbox;
