import { Shadows } from '@mui/material';

export const shadows = {
  _1: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
  _2: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)',
  _3: '0px 16px 20px 0px rgba(0, 0, 0, 0.10)',
  _4: '0px 30px 40px 0px rgba(0, 0, 0, 0.12)',
  _5: '0px 50px 60px 0px rgba(0, 0, 0, 0.15)'
}

export const shadowsArray: Shadows = [
  "none",
  shadows._1, shadows._2, shadows._3,
  shadows._4, shadows._5, 'none',
  'none', 'none', 'none', 'none',
  'none', 'none', 'none', 'none',
  'none', 'none', 'none', 'none',
  'none', 'none', 'none', 'none',
  'none', 'none'
]