import { ASSETS_URL } from '@op/entities';

export const fontWeights = {
  light: 300,
  normal: 450,
  medium: 500,
  semiBold: 550,
  bold: 700,
};

export const fontWeightBase = fontWeights.normal;

export const fontFamilies = {
  base: 'SuisseIntl, Arial, sans-serif',
};

export const fontRegular = {
  fontFamily: 'SuisseIntl',
  src: `local("SuisseIntl"),
                url(${ASSETS_URL}/fonts/SuisseIntl-Book.woff) format("woff"),
                url(${ASSETS_URL}/fonts/SuisseIntl-Book.woff2) format("woff2")`,
  fontWeight: fontWeights.normal,
  fontStyle: 'normal',
  fontDisplay: 'block',
};

export const fontMedium = {
  fontFamily: 'SuisseIntl',
  src: `local("SuisseIntl"),
                url(${ASSETS_URL}/fonts/SuisseIntl-Medium.woff) format("woff"),
                url(${ASSETS_URL}/fonts/SuisseIntl-Medium.woff2) format("woff2")`,
  fontWeight: fontWeights.medium,
  fontStyle: 'normal',
  fontDisplay: 'block',
};

export const fontSemiBold = {
  fontFamily: 'SuisseIntl',
  src: `local("SuisseIntl"),
                url(${ASSETS_URL}/fonts/SuisseIntl-Semibold.woff) format("woff"),
                url(${ASSETS_URL}/fonts/SuisseIntl-Semibold.woff2) format("woff2")`,
  fontWeight: fontWeights.semiBold,
  fontStyle: 'normal',
  fontDisplay: 'block',
};

export const fontFaces = `
@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl"),
  url(${ASSETS_URL}/fonts/SuisseIntl-Book.woff) format("woff"),
  url(${ASSETS_URL}/fonts/SuisseIntl-Book.woff2) format("woff2");
  font-weight: ${fontWeights.normal};
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl"),
  url(${ASSETS_URL}/fonts/SuisseIntl-Medium.woff) format("woff"),
  url(${ASSETS_URL}/fonts/SuisseIntl-Medium.woff2) format("woff2");
  font-weight: ${fontWeights.medium};
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl"),
  url(${ASSETS_URL}/fonts/SuisseIntl-Semibold.woff) format("woff"),
  url(${ASSETS_URL}/fonts/SuisseIntl-Semibold.woff2) format("woff2");
  font-weight: ${fontWeights.semiBold};
  font-style: normal;
  font-display: block;
}
`;
