import { DATE_FORMAT_UTC } from "@/shared/consts/common";
import { IFaqArticle, IFaqArticleDetail } from "@op/entities";
import moment from "moment";

export type flagsKeysTypes = keyof Pick<
  IFaqArticle,
  "is_popular" | "is_protect_trademark" | "is_register_product" | "is_draft"
>;

export const flagsDictionary: Record<flagsKeysTypes, string> = {
  is_popular: "IsPopular",
  is_protect_trademark: "isProtectTrademark",
  is_register_product: "IsRegisterProduct",
  is_draft: "Draft",
};

export const defaultValues: Partial<IFaqArticleDetail> = {
  publication_date_time_utc: moment(new Date())
    .startOf("day")
    .format(DATE_FORMAT_UTC),
  created_date_time_utc: moment(new Date())
    .startOf("day")
    .format(DATE_FORMAT_UTC),
  modified_date_time_utc: moment(new Date())
    .startOf("day")
    .format(DATE_FORMAT_UTC),

  index: "",
  title: "",
  image: "",
  description: "",
  tag_name: "",
  likes: 0,
  view_counts: 0,
  seo_description: "",
  seo_title: "",
  is_popular: false,
  is_protect_trademark: false,
  is_register_product: false,
  is_draft: false,
};

export const urlPattern = /^([a-zA-Z0-9_-]+)$/g;
