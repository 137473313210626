import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import MarkupStore from "../app/providers/MobxStore/model/MarkupStore";
import UserStore from "../app/providers/MobxStore/model/UserStore";
import { ROUTES } from "../app/providers/Router/model/consts/routerConsts";
import Link from "../shared/ui/Link";
import Author from "./Authors/Author";
import Dropdown from "./Dropdown";

function Header() {
  const navigate = useNavigate();
  const { toggleCreatorModalOpen } = MarkupStore;
  const { author: userAuthor, data: userData, isAuth } = UserStore;
  const { t } = useTranslation();

  const handleLogout = () => {
    UserStore.logout();
    navigate(ROUTES.HOME.path);
  };

  if (!isAuth || !userData) return null;

  return (
    <>
      <header>
        <div className="header">
          <div className="wrap">
            <div className="header__row">
              <div className="header__item"></div>
              <div className="header__item"></div>
              <div className="header__item">
                <button
                  className="btn btn-fond scale-up lighten-up size-s black header__btn-add desktop-only                  "
                  onClick={() => toggleCreatorModalOpen(true)}
                >
                  <span className="icon icon-plus btn__icon" />
                  <i className="btn__text"> {t("Create")}</i>
                </button>
                <div className="header__user">
                  <Author
                    initials={
                      userAuthor?.initials || userData?.username.slice(0, 2)
                    }
                    avatar={userAuthor?.avatar || ""}
                    className="header__user-pic"
                  />
                  <Dropdown
                    className="bottom-end"
                    parentClassName="header__user"
                    items={[
                      <React.Fragment key={0}>
                        <Author
                          initials={
                            userAuthor?.initials ||
                            userData?.username.slice(0, 2)
                          }
                          avatar={userAuthor?.avatar || ""}
                          name={userAuthor?.name || userData?.full_name}
                          className="dropdown__btn"
                        />
                      </React.Fragment>,
                      <React.Fragment key={1}>
                        <div className="dropdown__btn">
                          <Link
                            to={ROUTES.HOME.path}
                            className="header__user-dropdown-link"
                          >
                            <span className="icon icon-publications dropdown__icon" />
                            <em className="dropdown__text">
                              {t("ArticlesList")}
                            </em>
                          </Link>
                          <button
                            className="btn-icon header__user-dropdown-plus"
                            onClick={() => {
                              toggleCreatorModalOpen(true);
                            }}
                          >
                            <span className="icon icon-plus-black" />
                          </button>
                        </div>
                      </React.Fragment>,
                      <React.Fragment key={2}>
                        <button
                          className="dropdown__btn"
                          onClick={handleLogout}
                        >
                          {t("Exit")}
                        </button>
                      </React.Fragment>,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default observer(Header);
