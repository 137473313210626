import { transformPxToVw } from './transformPxToVw';

export const transformPxToVwInStyleObject = (styles: { [key: string]: string | number }, media: number) => {
  let styleObject = {};
  Object.entries(styles).map((item) => {
    if (typeof item[1] === 'string') {
      const newValue = item[1]
        .trim()
        .split(' ')
        .map((val) => {
          if (Number(val) === 0) {
            return val;
          }
          if (val.includes('px')) {
            return `${transformPxToVw(Number(val.replace('px', '')), media)}vw`;
          }
          return val;
        })
        .join(' ');
      styleObject = {
        ...styleObject,
        ...{ [item[0]]: `${newValue}` },
      };
    } else
      styleObject = {
        ...styleObject,
        ...{ [item[0]]: `${transformPxToVw(item[1], media)}vw` },
      };
    return styleObject;
  });
  return styleObject;
};
