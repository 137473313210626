import classNames from "classnames";
import React, { ReactElement } from "react";

interface IDropdown {
  className?: string;
  parentClassName?: string;
  items: ReactElement[];
  isOpen?: boolean;
}

const Dropdown = ({ className, parentClassName, items, isOpen }: IDropdown) => {
  return (
    <div
      className={classNames([
        "dropdown",
        className,
        parentClassName && `${parentClassName}-dropdown`,
        isOpen && "is-open",
      ])}
    >
      <ul
        className={classNames([
          "dropdown__list",
          parentClassName && `${parentClassName}-dropdown-list`,
        ])}
      >
        {items.map((item, index) => (
          <li
            key={index}
            className={classNames([
              "dropdown__item",
              parentClassName && `${parentClassName}-dropdown-item`,
            ])}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
