import { OpTheme } from '../../providers';

export const buttonScheme = {
  Xl: {
    minHeight: '88px',
    padding: OpTheme.spacing(7.5, 10.5),
    fontSize: '24px',
    lineHeight: 1.16,
    borderRadius: '12px',
    iconSize: '22px',
    iconMargin: OpTheme.spacing(4),
  },
  L: {
    minHeight: '76px',
    padding: OpTheme.spacing(6, 8),
    fontSize: '24px',
    lineHeight: 1.16,
    borderRadius: '12px',
    iconSize: '22px',
    iconMargin: OpTheme.spacing(4),
  },
  M: {
    minHeight: '60px',
    padding: OpTheme.spacing(4.5, 6),
    fontSize: '20px',
    lineHeight: 1.2,
    borderRadius: '12px',
    iconSize: '18px',
    iconMargin: OpTheme.spacing(2),
  },
  S: {
    minHeight: '52px',
    padding: OpTheme.spacing(4, 5),
    fontSize: '16px',
    lineHeight: 1.25,
    borderRadius: '12px',
    iconSize: '14px',
    iconMargin: OpTheme.spacing(2),
  },
  Xs: {
    minHeight: '40px',
    padding: OpTheme.spacing(3, 4),
    fontSize: '14px',
    lineHeight: 1.14,
    borderRadius: '8px',
    iconSize: '14px',
    iconMargin: OpTheme.spacing(2),
  },
  Xxs: {
    minHeight: '24px',
    padding: OpTheme.spacing(1, 2),
    fontSize: '14px',
    lineHeight: 1.14,
    borderRadius: '4px',
    iconSize: '12px',
    iconMargin: OpTheme.spacing(2),
  },
}